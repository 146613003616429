import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SiteSectionModel } from 'src/app/guru-api/models/site-section.model';

@Component({
  selector: 'app-section-detail-display',
  templateUrl: './section-detail-display.component.html',
  styleUrls: ['./section-detail-display.component.scss']
})
export class SectionDetailDisplayComponent implements OnInit {
  @Input() section: SiteSectionModel;
  imgSrc: string = ''
  openModal: boolean = false;
  showMore: boolean = false;
  readText: string = "Read More"
  descriptionText: string = ''

  constructor(private router: Router) { }

  ngOnInit(): void {

    
    // this.descriptionText = this.sectionTextAbbreviated();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) {
      if (this.descriptionText == '') {}
      if (this.section.description.length < 350) {
        this.readText = ''
        this.descriptionText = this.section.description;
        this.showMore = true;
        return
      }
      this.descriptionText = this.sectionTextAbbreviated();
    }
  }

  goToItem(id: number) {
    //If has a 360 SDK, then load to modal instead
    // const targetItem = this.section.items.find((si) => {
    //   return si.id == id;
    // })
    // if (targetItem.photos.length > 0) {
    //   const viewablePhoto = targetItem.photos.filter((vx) => {
    //     return vx.is_360_panorama_photo
    //   })
    //   console.log(viewablePhoto)
    //   if (viewablePhoto.length == 1) {

    //       // this.setViewer(viewablePhoto[0].image.full)
        
    //   } else {
    //     this.router.navigate(['/item/' + id])
    //   }
      

    // } else {
      this.router.navigate(['/item/' + id])
    // }

  }
  toggleReadMore() {
    this.showMore = !this.showMore

    if (!this.showMore) {
      this.readText = "Read More"
      setTimeout(() => {
        this.descriptionText = this.sectionTextAbbreviated();
      }, 500)
    } else {
      this.readText = "Read Less"
      this.descriptionText = this.section.description
    }
  }
  sectionText() {
    if (this.showMore) {
      return this.section.description
    } else {
      return this.sectionTextAbbreviated()
    }
  }
  sectionTextAbbreviated() {
    return this.section.description.substring(0, 300).trim()+ "...";
  }

}
