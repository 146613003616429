import {Component, OnInit, OnDestroy} from '@angular/core';
import {GuruApiAppService} from '../guru-api/guru-api-app.service';
import {AppDetailsModel} from '../guru-api/models/app-details.model';
import {Subscription} from 'rxjs';
import {AppConfigService} from '../services/app-config/app-config.service';
import {CookieService} from 'ngx-cookie-service';
import { LoadingService } from '../services/loading/loading.service';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss']
})
export class LanguagePageComponent implements OnInit, OnDestroy {
  selected: string;
  langCodes = ['en'];
  appDetails: AppDetailsModel;
  appDetailsSubscription$: Subscription;
  loadingServiceSubscription$: Subscription;
  isLoading = true;
  showPermissions= true;
  splashComplete = false;
  setFromPreferences = false;
  constructor(private guruApiAppService: GuruApiAppService,
              private  appConfigService: AppConfigService,
              private cookieService: CookieService,
              private loadingService: LoadingService,
              private router: Router) {
    this.loadingService.appState.subscribe(state =>{
      if(state){
        this.loadAppDetails();
      }
    })
  }

  ngOnInit(): void {
    if (!this.loadingService.noInternet) {
      console.log("no internet on language")
      return
    }
    if(this.loadingService.appState.value){
      this.loadAppDetails();
    }
  }
  ngOnDestroy(): void{
    if(this.appDetailsSubscription$) {
      this.appDetailsSubscription$.unsubscribe();
    }
    if(this.loadingServiceSubscription$){
      this.loadingServiceSubscription$.unsubscribe();
    }
  }

  getLangName(code: string): string{
    return this.guruApiAppService.getLangName(code);
  }

  onClick() {
    // console.log(this.selected)
    if (this.selected) {
      Preferences.set({
        key:'language',
        value: this.selected
      }).then(() => {
        console.log("language set")
      }).then(() => {
        // this.selected = language;
        this.cookieService.set('language', this.selected);
        this.appConfigService.language = this.selected;
        this.router.navigate(["/home"])
      })
    }
  }
 
  onSelect(language: string): void {
    // this.loadingService.show();
    console.log("language?", language)
    Preferences.set({
      key:'language',
      value: language
    }).then(() => {
      console.log("language set")
    }).then(() => {
      this.selected = language;
      this.cookieService.set('language', this.selected);
      this.appConfigService.language = this.selected;
      this.router.navigate(["/home"])
    })

    
  }

  initLanguage(appDetails: AppDetailsModel): void{
    this.langCodes = [...this.langCodes, ...appDetails.languages];
    this.langCodes = [...new Set(this.langCodes)];
    this.appConfigService.setLanguages(this.langCodes);
    if (this.guruApiAppService.deviceInfo == "web") {
      if(this.cookieService.check('language')) {
        this.selected = this.cookieService.get('language');
      } else {
        this.selected = this.langCodes[0];
        this.cookieService.set('language', this.selected);
      }
    } else {
      Preferences.get({
        key: "language"
      }).then((val) => {
        if (val.value) {
          this.selected = val.value
        } else {
          this.selected = this.langCodes[0]
        }
      })
    }
    
  }

  loadAppDetails(): void{
    const appDetails = this.guruApiAppService.getAppDetails();
    if(appDetails){
      this.initLanguage(appDetails);
      this.isLoading = false;
      return;
    }

    this.appDetailsSubscription$ = this.guruApiAppService.loadAppDetails().subscribe(
      data => {
        this.initLanguage(data.objects[0]);
        this.isLoading = false;
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      }
    );
  }
  getPermissionsComplete(evt) {
    console.log(evt);
    console.log("permissions complete");
    console.log("get pref")

      Preferences.get({
        key: 'language'
      }).then((langVal) => {
        // console.log("langval", langVal.value)
        if (langVal.value) {
          // this.onSelect(langVal.value)
          this.setFromPreferences = true;
          this.selected = langVal.value
          if (!this.screenClosed) {
            this.onClick();
          }
          
        } 
      })
    this.showPermissions = false;
  }
  screenClosed(evt) {
    console.log("splash closed")
    if (!this.showPermissions && this.selected && this.setFromPreferences) {
      this.onClick();
    } 
  } 
  hasInternet(): boolean {
    // return false
    return !this.loadingService.noInternet
  }
}
