import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { Router } from '@angular/router';
import { LanguagePageComponent } from './language-page/language-page.component';
import { SplashPageComponent } from './splash-page/splash-page.component';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './home-page/home-page.component';
import { FormsModule } from '@angular/forms';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { TourListComponent} from './tour-list/tour-list.component';
import { SearchTourPageComponent} from './search-tour-page/search-tour-page.component';
import { TourIntroPageComponent } from './tour-intro-page/tour-intro-page.component';
import { TourStopsComponent } from './tour-stops/tour-stops.component';
import { TourStopPageComponent } from './tour-stop-page/tour-stop-page.component';
import { MatSliderModule } from '@angular/material/slider';
import { LanguageInterceptorService } from './services/language/language-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MapListComponent } from './map-list/map-list.component';
import { MapPageComponent } from './map-page/map-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { ItemListComponent } from './item-list/item-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { LogoComponent } from './logo/logo.component';
import { WINDOW_PROVIDERS } from './window';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../environments/environment';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryChildrenListComponent } from './category-children-list/category-children-list.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { GalleryItemModalComponent } from './widgets/gallery-item-modal/gallery-item-modal.component';
import { SafePipe } from './pipes/safe/safe.pipe';
import { EventCarouselComponent } from './event-carousel/event-carousel.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { EventsPageComponent } from './events-page/events-page.component';
import { EventsItemPageComponent } from './events-item-page/events-item-page.component';
import { ArcmapComponent } from './arcmap/arcmap.component';
import { TourCarouselComponent } from './tour-carousel/tour-carousel.component';
import { MembershipCardComponent } from './membership-card/membership-card.component';
import { DiningCarouselComponent } from './dining-carousel/dining-carousel.component';
import { SiteSectionCarouselComponent } from './site-section-carousel/site-section-carousel.component';
import { InfoCarouselComponent } from './info-carousel/info-carousel.component';
import { DiningItemComponent } from './dining-item/dining-item.component';
import { SiteSectionItemComponent } from './site-section-item/site-section-item.component';
import { InfoItemComponent } from './info-item/info-item.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { InfoDetailDisplayComponent } from './detail-displays/info-detail-display/info-detail-display.component';
import { DiningDetailDisplayComponent } from './detail-displays/dining-detail-display/dining-detail-display.component';
import { SectionDetailDisplayComponent } from './detail-displays/section-detail-display/section-detail-display.component';
import { GenericCarouselComponent } from './generic-carousel/generic-carousel.component';
import { IonicModule } from '@ionic/angular';
import { MoreInfoPageComponent } from './more-info-page/more-info-page.component';
import { HoursPageComponent } from './hours-page/hours-page.component';
import { BuyTicketsComponent } from './buy-tickets/buy-tickets.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NewsCarouselComponent } from './news-carousel/news-carousel.component';
import { NewsDetailDisplayComponent } from './detail-displays/news-detail-display/news-detail-display.component';
import { PermissionsScreenComponent } from './permissions-screen/permissions-screen.component';
import { NavDotsComponent } from './nav-dots/nav-dots.component';
import { NoDataPageComponent } from './no-data-page/no-data-page.component';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import { NgxPanoViewerModule } from "@egjs/ngx-view360";
import { TramTourDisplayComponent } from './detail-displays/tram-tour-display/tram-tour-display.component';

if(environment.production) {
  Sentry.init({
    dsn: 'https://b6e36cb1fc6f49239c53ba743fae1fd6@o494649.ingest.sentry.io/5843023',
    environment: 'production',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
} else {
  Sentry.init({
    dsn: 'https://b6e36cb1fc6f49239c53ba743fae1fd6@o494649.ingest.sentry.io/5843023',
    environment: 'development',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LanguagePageComponent,
    SettingsPageComponent,
    SplashPageComponent,
    BottomMenuComponent,
    HomePageComponent,
    MoreMenuComponent,
    AboutUsPageComponent,
    TourListComponent,
    SearchTourPageComponent,
    TourIntroPageComponent,
    TourStopsComponent,
    TourStopPageComponent,
    MapListComponent,
    MapPageComponent,
    ItemDetailsComponent,
    ItemListComponent,
    LoadingPageComponent,
    LogoComponent,
    CategoryListComponent,
    CategoryChildrenListComponent,
    CategoryDetailsComponent,
    ImageGalleryComponent,
    GalleryItemModalComponent,
    SafePipe,
    EventCarouselComponent,
    TopNavComponent,
    EventsPageComponent,
    EventsItemPageComponent,
    ArcmapComponent,
    TourCarouselComponent,
    MembershipCardComponent,
    DiningCarouselComponent,
    SiteSectionCarouselComponent,
    InfoCarouselComponent,
    DiningItemComponent,
    SiteSectionItemComponent,
    InfoItemComponent,
    DetailPageComponent,
    InfoDetailDisplayComponent,
    DiningDetailDisplayComponent,
    SectionDetailDisplayComponent,
    GenericCarouselComponent,
    MoreInfoPageComponent,
    HoursPageComponent,
    BuyTicketsComponent,
    NewsCarouselComponent,
    NewsDetailDisplayComponent,
    PermissionsScreenComponent,
    NavDotsComponent,
    NoDataPageComponent,
    VideoCarouselComponent,
    TramTourDisplayComponent
  ],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        MatSliderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }),
        MatProgressSpinnerModule,
        IonicModule.forRoot(),
        QRCodeModule,
        NgxPanoViewerModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true
    },
    CookieService,
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
