<!-- <p>option-carousel works!</p> -->
<!-- <div class="titling"> -->
    <!-- <span class="activities-title">{{'dining-list.activities' | translate | titlecase}}</span> -->
    <!-- <span class="see-more" >{{'dining-list.seemore' | translate | uppercase}}</span> -->
<!-- </div> -->
<!-- <div class="container-fluid option-container">
    <div *ngIf="options?.length" class="row option-list-horizontal">
        <div *ngFor="let option of options">
            <div class="col" (click)="goToDining(option.id)">
                <img [src]="option.main_photo.original" class="option-image"/>
                <div class="option-info">
                    <div class="option-name">
                        <p>{{option.info_name}}</p>
                    </div>

                </div>
            </div>
        </div>
      </div>
</div> -->
<app-generic-carousel
    [rowTitle]="'dining-list.activities'"
    [items]="options"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item >
    <div (click)="goToDining(item.id)">
        <img [src]="item.main_photo.thumb_manager" class="carousel-item-image-single"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.info_name}}</p>
            </div>

        </div>
    </div>
</ng-template>