import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { NewsItem } from 'src/app/guru-api/models/news.model';

@Component({
  selector: 'app-news-detail-display',
  templateUrl: './news-detail-display.component.html',
  styleUrls: ['./news-detail-display.component.scss']
})
export class NewsDetailDisplayComponent implements OnInit {
  @Input() news: NewsItem
  @ViewChild('container') container; 
  constructor() { }

  ngOnInit(): void {
    console.log(window)
    window.scrollTo(0, 0);
    console.log(window)
  }
  ngAfterViewInit() {
      window.scrollTo(0, 0);
      console.log(this.container.nativeElement)

  }

}
