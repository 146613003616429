<app-generic-carousel
    [rowTitle]="'360-tours.title'"
    [items]="virtualTours"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item>
    <div class="col" (click)="setViewer(item.photo.image.full)">
        <img [src]="item.photo.image.original" class="carousel-item-image-single"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.title}}</p>
            </div>

        </div>
    </div>
</ng-template>
<ion-modal [isOpen]="openModal" (didPresent)="modalOpen($event)">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-buttons>
                    <ion-button (click)="closeViewer()">Close</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div id="panoViewer" style="position: relative; height: 100%; width: 100%;"></div>
            <!-- <ngx-pano-viewer [image]="imgSrc" style="position: relative; height: 200px; width: 200px;"></ngx-pano-viewer> -->
        </ion-content>
    </ng-template>
</ion-modal>
<div id="pano-holder"></div>
<!-- <div *ngIf="openModal" class="pano-holder">
    <ngx-pano-viewer *ngIf="imgSrc != ''" [image]="imgSrc"></ngx-pano-viewer>
</div> -->
<!-- <ngx-pano-viewer *ngIf="imgSrc != ''" [image]="imgSrc"></ngx-pano-viewer> -->
<!-- <ngx-view360 class="is-16by9" [options]="options" /> -->