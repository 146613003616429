import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoreMenuStateService {
  private isOpen = false;

  constructor() {
  }

  toggleState(): void {
    this.isOpen = !this.isOpen;
  }

  getState(): boolean {
    return this.isOpen;
  }

  setState(state: boolean): void {
    this.isOpen = state;
  }
}
