<!-- <p>events-page works!</p> -->
<!-- <div class="cal-holder"> -->
    <p class="page-title">{{'events-page.title' | translate | titlecase}}</p>
    <div class="container-fluid" style="height: 84vh;">
        <div class="calendar">
            <div class="header">
              <!-- {{currentDate | date:'MMMM y'}} -->
              <div class="header-left">
                <span>{{formatDisplayDate()}}</span>
              </div>
              <div class="header-center">
                <i class="fas fa-chevron-left" (click)="navigateMonth(-1)"></i>
                <i class="fas fa-chevron-right" (click)="navigateMonth(1)"></i>
    
            </div>
            <div class="header-right">
              <div *ngIf="!notCurrentMonth">
                <ion-button *ngIf="viewMode == 0" (click)="toggleViewMode()">
                  <i class="fas fa-grip-lines"></i>
                </ion-button>
                <ion-button  *ngIf="viewMode == 1" (click)="toggleViewMode()">
                  <i class="fas fa-grip-horizontal"></i>
                </ion-button>
              </div>
            </div>
            </div>
            <div class="calendar-display">
                <div class="week-days">
                    <div class="day" *ngFor="let day of weekDays">{{day}}</div>
                  </div>
                  <div class="days">
                    
                    <div class="day" *ngFor="let day of days">
                      <div class="day-button" *ngIf="day != null" (click)="setDay(day)" [ngClass]="{'active': selectedDay == day}">
                          <p class="date-text">{{day}}</p>
                          <div class="event-indicator" *ngIf="dateHasEvent(day)"></div>
                      </div>
                      <div class="day-empty" *ngIf="day == null">
                        
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="event-list" id="event-list">
            <div *ngFor="let event of viewableEventList()" >
                <div class="event-listing" (click)="goToEvent(event.event.id, event.start_time)" [id]="'event-'+event.event.id + event.start_time">
                    <!-- <div> -->
                        <img class="event-image" [src]="event.event.default_image.thumb_manager"/>
                    <!-- </div> -->
                    <div class="event-desc">
                        <p class="title">{{event.event.name}}</p>
                        <p class="timing">{{formatEventDate(event)}}</p>
                        <p class="timing">{{formatEventDuration(event)}}</p>
                    </div>
                </div>
            </div>
          </div>
    </div>
    <!-- <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next" class="cal"></ngb-datepicker> -->
    <!-- <div class="d-inline-block"> -->

        <!-- <div class="btn-group d-flex justify-content-end mb-2" role="group">
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="navigate(-1);">Prev</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="today()">Today</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="navigate(1)">Next</button>
        </div> -->
      
        <!-- <ngb-datepicker class="calpicker"
                        #dp
                        [displayMonths]="1"
                        outsideDays="hidden"
                        [showWeekdays]="false"
                        [dayTemplate]="dayTemplate"
                        navigation="none">
          <ng-template ngbDatepickerContent>
            <div *ngFor="let month of currentMonth()">
              <div class="month-header">
                <div class="month-name">{{ i18n.getMonthFullName(month.month) }} {{ month.year }}</div>
                <div>
                    <i class="fas fa-chevron-left"></i>
                    <i class="fas fa-chevron-right"></i>

                </div>
              </div>
              <ngb-datepicker-month class="monthpicker" [month]="month"></ngb-datepicker-month>
            </div>
          </ng-template>
          
        </ngb-datepicker>
        <ng-template #dayTemplate let-date>
            <div class="custom-day">
              <span>
                {{ date.day }}
              </span>
            </div>
          </ng-template> -->
      <!-- </div> -->
<!-- </div> -->