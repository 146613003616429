import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryModel } from '../guru-api/models/category.model';
import { GuruApiIteminfoService } from '../guru-api/guru-api-iteminfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GuruApiCategoryService } from '../guru-api/guru-api-category.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { Subject } from 'rxjs';
import { CategoryItemModel } from '../guru-api/models/category-item.model';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit, OnDestroy{
  category: CategoryModel;
  categoryItems: CategoryItemModel[];
  id?: number;

  traceTimeSpent = null;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private guruApiItemInfoService: GuruApiIteminfoService,
    private route: ActivatedRoute,
    private router: Router,
    private guruApiCategoryService: GuruApiCategoryService,
    private guruFirebaseService: GuruFirebaseService,
    private loadingService: LoadingService) {
      this.id = +route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.show && (!this.categoryItems && !this.categoryItems?.length)){
          this.traceTimeSpent = this.guruFirebaseService.performance.trace('Time Spent On Category Details Page');
          this.traceTimeSpent.start();
          this.loadCategoryItems();
        }
      });
  }

  ngOnDestroy(): void {
    this.traceTimeSpent.stop();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadCategoryItems(): void{
    const traceCategoryDetailsLoad = this.guruFirebaseService.performance.trace('Category Details Load')
    traceCategoryDetailsLoad.start();

    this.category = this.guruApiCategoryService.getCategory(this.id);

    this.guruApiItemInfoService.getList<CategoryItemModel>({categories__in: this.id})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        traceCategoryDetailsLoad.stop();
        this.categoryItems = data.objects;
      }, error => {
        traceCategoryDetailsLoad.stop();
        console.log(error);
      });
  }

}
