import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { DisplaySettingsService } from '../services/display-settings/display-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit, OnDestroy {
  show = true;
  opacity: number;
  splash_screen: string;
  displaySettingsSubscription$: Subscription;
  @Output() screenClosed: EventEmitter<boolean> = new EventEmitter();
  constructor(private displaySettingsService: DisplaySettingsService) {
  }

  ngOnInit(): void {
    console.log("splash init")
    this.displaySettingsSubscription$ = this.displaySettingsService.getConfig().subscribe( config => {
      if(config.splashLogo && config.splashLogo.length) {
        // this.splash_screen = config.splashLogo;
        this.splash_screen = "./assets/img/large-SCH_Vert_Logo_White_RGB.png"
      }
    });

    setTimeout(() => {
      setTimeout(() => {
        this.show = false;
        console.log("screen done")
        this.screenClosed.emit(true)
      }, 100);
      this.opacity = 0;
    }, 3000);
  }

  ngOnDestroy(): void{
    this.displaySettingsSubscription$ && this.displaySettingsSubscription$.unsubscribe();
  }
}
