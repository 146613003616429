import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { Device } from '@capacitor/device';
import { Router, NavigationEnd } from '@angular/router';
import { NavService } from '../services/nav.service';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  @Input() isWeb = 'isWeb';
  @ViewChild('outlet') outlet; 
  debug: boolean = false;
  // isWeb: boolean = true;
  constructor(
    private _location: Location,
    private router: Router,
    private navService: NavService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
      this.outlet.nativeElement.scrollTo(0,0)
  });
  }

  backClicked(): void {
    if (this.isHome()) {
      return
    }
    if (this.navService.hasPageIntercepts(this.router.url)) {
      console.log("has intercepts")
      this.navService.decPageIntercept(this.router.url)
      return
    }
    this._location.back();
  }
  isHome(): boolean {
    return this.router.url.includes("home")
  }
}
