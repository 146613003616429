<app-no-data-page *ngIf="!hasInternet()"></app-no-data-page>
<app-loading-page></app-loading-page>
<app-permissions-screen *ngIf="showPermissions" (screenComplete)="getPermissionsComplete($event)"></app-permissions-screen>

<div *ngIf="!isLoading && !showPermissions">

<app-splash-page (screenClosed)="screenClosed($event)"></app-splash-page>
<div class="language-container">
  <app-logo></app-logo>
  <div class="message-box">
    <h2>{{'language-page.hello' | translate | titlecase}}!</h2>
    <h3>{{'language-page.choose-your-language' | translate}}</h3>
  </div>

  <div class="language-list">
    <ul>
      <li class="language"
          *ngFor="let language of langCodes"
          (click)="onSelect(language)"
      >
        <span [ngClass]="selected===language ? 'selected-lang' : 'not-selected'">{{this.getLangName(language).name | uppercase}}</span>
        <div *ngIf="selected===language" class="ok-icon"></div>
      </li>
    </ul>
  </div>
  <ion-button class="start-button" (click)="onClick()">{{'language-page.start' | translate | titlecase}}</ion-button>
</div>
</div>
