<!-- <p>info-carousel works!</p> -->
<!-- <div class="titling"> -->
    <!-- <span class="activities-title">{{'infos-list.activities' | translate | titlecase}}</span> -->
    <!-- <span class="see-more" (click)="goToInfos()">{{'infos-list.seemore' | translate | uppercase}}</span> -->
<!-- </div> -->
<!-- <div class="container-fluid info-container">
    <div *ngIf="infos?.length" class="row info-list-horizontal">
        <div *ngFor="let info of infos">
            <div class="col" (click)="goToInfo(info.id)">
                <img [src]="info.banner_image.original" class="info-image"/>
                <div class="info-info">
                    <div class="info-name">
                        <p>{{info.title}}</p>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
      </div>
</div> -->

<app-generic-carousel
    [rowTitle]="'infos-list.activities'"
    [items]="infos"
    [itemTemplate]="itemTemplate"
    [singleElement]="false"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item >
    <div (click)="goToInfo(item.id)">
        <img [src]="item.banner_image.original" class="carousel-item-image"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.title}}</p>
            </div>

        </div>
    </div>
</ng-template>