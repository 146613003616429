import { Injectable } from '@angular/core';
import { GuruApiAboutService } from 'src/app/guru-api/guru-api-about.service';
import { GuruApiAppService } from 'src/app/guru-api/guru-api-app.service';
import { GuruApiCategoryService } from 'src/app/guru-api/guru-api-category.service';
import { GuruApiDineGiftService } from 'src/app/guru-api/guru-api-dinegift.service';
import { GuruApiEventService } from 'src/app/guru-api/guru-api-events.service';
import { GuruApiInfoService } from 'src/app/guru-api/guru-api-info.service';
import { GuruApiIteminfoService } from 'src/app/guru-api/guru-api-iteminfo.service';
import { GuruApiMapService } from 'src/app/guru-api/guru-api-map.service';
import { GuruApiNewsService } from 'src/app/guru-api/guru-api-news.service';
import { GuruApiToursService } from 'src/app/guru-api/guru-api-tours.service';
import { GuruApiVirtualToursService } from 'src/app/guru-api/guru-api-virtual-tour.service';
import { AppConfigService } from '../app-config/app-config.service';
import { MoreMenuStateService } from '../more-menu-state.service';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {
  services: ClearableAPI[];

  constructor(private guruApiToursService: GuruApiToursService,
    private guruApiAppService: GuruApiAppService,
    private guruApiAboutService: GuruApiAboutService,
    private guruApiIteminfoService: GuruApiIteminfoService,
    private moreMenuStateService: MoreMenuStateService,
    private appConfigService: AppConfigService,
    private guruApiCategoryService: GuruApiCategoryService,
    private guruApiEventService: GuruApiEventService,
    private guruApiInfoService: GuruApiInfoService,
    private guruApiDineGiftService: GuruApiDineGiftService,
    private guruApiSectionService: GuruApiMapService,
    private guruNewsService: GuruApiNewsService,
    private guruVirtualTourService: GuruApiVirtualToursService) { 
      this.services = [
        guruApiInfoService,
        guruApiIteminfoService,
        guruApiCategoryService,
        guruApiEventService,
        guruApiInfoService,
        guruApiDineGiftService,
        guruApiSectionService,
        guruNewsService,
        guruVirtualTourService,
      ]
    }
  clearCurrentData() {
    this.services.forEach(sx => {
      sx.clearData();
    })
  }
}

export interface ClearableAPI {
  clearData()
}