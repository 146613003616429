import { Component, OnInit, ViewChild } from '@angular/core';
import { GuruApiVirtualToursService } from '../guru-api/guru-api-virtual-tour.service';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { VideoModel } from '../guru-api/models/video.model';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { VirtualTourModel } from '../guru-api/models/virtual-tour.model';
import { PhotoModel } from '../guru-api/models/photo.model';
import { PROJECTION_TYPE, PanoViewer } from '@egjs/ngx-view360';
import { IonModal } from '@ionic/angular';

// import View360, { EquiangularProjection, EquirectProjection, View360Options } from '@egjs/ngx-view360';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  unsubscribe$: Subject<void> = new Subject<void>();
  virtualTours: PhotoModel[] = [];
  imgSrc: string = ''
  openModal: boolean = false;
  // options: Partial<View360Options> = {
  //   projection: new EquirectProjection({
  //     src: "/egjs-view360/pano/equirect/veste.jpg",
  //   })
  // }
  constructor(
    private guruVirtualTourService: GuruApiVirtualToursService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        // console.log("ng on init")
        this.loadVirtualTours();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadVirtualTours(): void {
    const virtualTours = this.guruVirtualTourService.getVirtualTours()
    console.log(virtualTours)
    if (virtualTours) {
      this.virtualTours = virtualTours[0]["photos"]
      return
    }
    console.log("start request virtual tours")
    this.guruVirtualTourService.loadVirtualTours()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      console.log(data)
      this.virtualTours = data["photos"]
      this.guruVirtualTourService.setVirtualTours([data])
      // this.unsubscribe$.complete();
    })
  }
  modalOpen($event) {
    console.log($event)
    console.log(document.getElementById("panoViewer"))
    const panoViewer = new PanoViewer(
      document.getElementById("panoViewer"),
      {
        // image: this.imgSrc,
        projectionType: PROJECTION_TYPE.PANORAMA
      },
    );
    const sensorRes = panoViewer.enableSensor().then((val) => {
      console.log(val)
      panoViewer.setImage(this.imgSrc)
      panoViewer.updateViewportDimensions()
    }).catch((val) => {
      console.log("catch", val)
    })
    console.log(sensorRes)
    
  }
  setViewer(url: string) {
    const panoViewer = new PanoViewer(document.getElementById("pano-holder"), {}).enableSensor().catch(err => console.log(err))
    console.log("pano", panoViewer)
    // this.modal.on
    this.openModal = true
    // console.log(this.modal.template.elementRef)

    this.imgSrc = url
    // this.modal.component
    // const panoViewer = new PanoViewer(
    //   this.modal.getElementById(":host#panoViewer"),
    //   {
    //     image: this.imgSrc
    //   }
    // );
    
    // this.options.projection = new EquirectProjection({
    //   src: url
    // })
  }
  closeViewer() {
    this.openModal = false
  }
}
