import { Injectable } from '@angular/core';
import { GuruApiIteminfoService } from 'src/app/guru-api/guru-api-iteminfo.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GuruApiCategoryService } from 'src/app/guru-api/guru-api-category.service';
import { GuruApiEventDetailService } from 'src/app/guru-api/guru-api-event-detail.service';
import { TourItemModel } from 'src/app/guru-api/models/tour-item.model';
import { CategoryModel } from 'src/app/guru-api/models/category.model';
import { GuruApiInfoService } from 'src/app/guru-api/guru-api-info.service';
import { InfoModel } from 'src/app/guru-api/models/info.model';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchableItems: {
    "items": SearchResult[],
    "categories": SearchResult[],
    "info": SearchResult[]
  }
  unsubscribe$: Subject<void> = new Subject<void>();
  constructor(  
    private itemService: GuruApiIteminfoService,
    private categoryService: GuruApiCategoryService,
    private infoService: GuruApiInfoService
    // private eventService: GuruApiEventDetailService
  ) {
    if (!this.searchableItems) {
      this.searchableItems = {
        items: new Array<SearchResult>(),
        categories: new Array<SearchResult>(),
        info: new Array<SearchResult>(),
      }
    }
    // this.searchableItems.items = new Array<SearchResult>();
    this.loadItems();
    this.loadCategories();
    this.loadInfo();
  }

  searchForTerm(searchTerm: string): SearchResult[] {
    const lowercaseSearchTerm = searchTerm.toLowerCase()
    const items = this.searchableItems.items.filter((sx) => {
      return sx.title.toLowerCase().includes(lowercaseSearchTerm) || sx.description?.toLowerCase().includes(lowercaseSearchTerm)
    })
    // console.log(items)
    const categories = this.searchableItems.categories.filter((sx) => {
      return sx.title.toLowerCase().includes(lowercaseSearchTerm) || sx.description?.toLowerCase().includes(lowercaseSearchTerm)
    })
    const infos = this.searchableItems.info.filter((sx) => {
      return sx.title.toLowerCase().includes(lowercaseSearchTerm) || sx.description?.toLowerCase().includes(lowercaseSearchTerm)
    })
    return [...items, ...categories, ...infos]
  }

  mapItemsForSearch(items: TourItemModel[]) {
    const mappedItems = items.map((tm) => {
      return {
        id: tm.id,
        display_image: tm.image?.square,
        link: '/item/' + tm.id,
        title: tm.name,
        description: tm.description,
        source_type: 'item'
      } as SearchResult
    })
    console.log(mappedItems)
    this.searchableItems.items = mappedItems
  }
  mapCategories(categories: CategoryModel[]) {
    const mappedCategories = categories.map((cx) => {
      return {
        id: cx.id,
        display_image: cx.default_photo.square,
        link: '/category/' + cx.id,
        title: cx.name,
        // description: cx.
        source_type: 'category'
      }
    })
    this.searchableItems.categories = mappedCategories
  }
  mapInfo(info: InfoModel[]) {
    const mappedInfo = info.map((inf) => {
      console.log(inf)
      return {
        id: inf.id,
        display_image: inf.banner_image.original,
        title: inf.title,
        description: inf.description,
        source_type: 'info',
        link: '/detail/info/' + inf.id,
      }
    } )
    console.log(mappedInfo)
    this.searchableItems.info = mappedInfo
  }
  loadItems() {
    const items = this.itemService.getItemsInfo()
    if (items && items.length) {
      console.log("existing items", items)
      this.mapItemsForSearch(items)
      return
    }
    this.itemService.loadItemsInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.itemService.setItemsInfo(data.objects);
          // this.items = data.objects;
          // this.sortItems();
          this.mapItemsForSearch(this.itemService.getItemsInfo())
          console.log('Items loaded!');
        },
        error => {
          console.log('error', error);
        });
  }
  loadCategories() {
    const categories = this.categoryService.getCategories()
    if (categories && categories.length) {
      // console.log("existing items", categories)
      this.mapCategories(categories)
      return
    }
    this.categoryService.loadCategories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const categories = data.objects.filter(c => c).sort((a, b) => a.order_num - b.order_num);
        this.categoryService.setCategories(categories);
        this.mapCategories(categories)

        
      }, error => {
        console.log('error', error);
      });
  }
  loadInfo() {
    const infos = this.infoService.getInfos();
    if (infos && infos.length) {
      // this.filterTours(tours);
      // this.infos = infos.slice(0, 10);
      this.mapInfo(infos);
      return;
    }

    this.infoService.loadInfos()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        this.mapInfo(data.objects)
      }
    )
  }
} 

export class SearchResult {
  id: number
  display_image: string
  link: string
  title: string
  description?: string
  source_type: string
}
