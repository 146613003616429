<div class="home-container">
  <!-- <app-logo></app-logo> -->
  <app-event-carousel [todayOnly]="true"></app-event-carousel>
  <!-- <app-tour-carousel></app-tour-carousel> -->
  <app-news-carousel></app-news-carousel>
  <app-site-section-carousel></app-site-section-carousel>
  <!-- <app-category-list [text]="'audio tours' | translate"></app-category-list> -->
  <app-dining-carousel></app-dining-carousel>
  <app-membership-card></app-membership-card>


  <!-- <h1>{{'home-page.tours' | translate | titlecase}}</h1> -->
  <!-- <app-tour-list [text]="'home-page.available-tours' | translate"></app-tour-list> -->
</div>
