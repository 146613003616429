import { Injectable } from '@angular/core';
import { DisplayConfig } from './display-config.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplaySettingsService {
  config: BehaviorSubject<DisplayConfig> = new BehaviorSubject<DisplayConfig>({
    primaryColor: '',
    secondaryColor: '',
    splashLogo: '',
    museumLogo: ''
  });

  constructor() {

  }

  setConfig(config: DisplayConfig): void{
    this.config.next(config);
  }

  getConfig(): Observable<DisplayConfig>{
    return this.config.asObservable();
  }
}
