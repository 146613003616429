import { Component, Input, OnInit } from '@angular/core';
import { PhotoModel } from '../guru-api/models/photo.model';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { animate, style, transition, trigger } from '@angular/animations';
import { VideoModel } from '../guru-api/models/video.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryItemModalComponent } from '../widgets/gallery-item-modal/gallery-item-modal.component';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  animations: [
    trigger(
      'inAndOutAnimation',
        [
          transition(
            ':enter',
            [
              style({
                height: '0',
                opacity: '0'
              }),
              animate('.5s ease-out',
                style({
                  height: '100%',
                  opacity: '1'
                }))
            ]
          ),
          transition(
            ':leave',
            [
              style({
                height: '100%',
                opacity: '1'
              }),
              animate('.5s ease-in',
                style({
                  height: '0',
                  opacity: '0'
                }))
            ]
          )
        ]
    )
  ]
})

export class ImageGalleryComponent implements OnInit {
  @Input() photoObj?: PhotoModel[];
  @Input() videoObj?: VideoModel[];
  @Input() show? = true;
  @Input() text?: string;

  caption?: string

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  showItem(index: number): void{
    const modalRef = this.modalService.open(GalleryItemModalComponent, {centered: true, modalDialogClass: 'gallery-modal' });
    if(this.photoObj && this.photoObj.length){
      modalRef.componentInstance.photoObj = this.photoObj;
    } else {
      modalRef.componentInstance.videoObj = this.videoObj;
    }

    modalRef.componentInstance.index = index;
  }

  getUrl(index: number): string{
    if(this.photoObj && this.photoObj.length){
      return this.photoObj[index].image?.square;
    }
    return this.videoObj[index].video?.image?.big_retina;
  }

  getObjects(): PhotoModel[] | VideoModel[]{
    if(this.photoObj && this.photoObj.length){
      return this.photoObj;
    }
    return this.videoObj;
  }


}
