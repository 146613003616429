import { Component, Input, OnInit } from '@angular/core';
// import { InfoItemModel, InfoResponseModel } from '../guru-api/models/infos.model';
import { Subject, Subscription } from 'rxjs';
import { GuruApiInfoService } from '../guru-api/guru-api-info.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {Router} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { InfoModel } from '../guru-api/models/info.model';

@Component({
  selector: 'app-info-carousel',
  templateUrl: './info-carousel.component.html',
  styleUrls: ['./info-carousel.component.scss']
})
export class InfoCarouselComponent implements OnInit {
  @Input() todayOnly?: boolean = false;
  infos: InfoModel[];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private guruApiInfoService: GuruApiInfoService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadInfos();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadInfos(): void {
    const infos = this.guruApiInfoService.getInfos();
    if (infos && infos.length) {
      // this.filterTours(tours);
      // this.infos = infos.slice(0, 10);
      this.filterData(infos);
      return;
    }

    this.guruApiInfoService.loadInfos()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        this.filterData(data.objects)
      }
    )
  }
  filterData(infosData: InfoModel[]) {
    let infoList = infosData;
  
    this.infos = infoList;
    this.guruApiInfoService.setInfos(infoList);
  }
  formatDate(toParse: number): string {
    var setDate = moment.unix(toParse);
    return setDate.calendar() + " " + setDate.format("LT");
  }
  goToInfos() {
    this.router.navigate(['infos']);
  }
  goToInfo(id: number) {
    this.router.navigate(["detail/info/" + id])
  }
}
