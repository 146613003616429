import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import {EGalaxyTicket, Member} from '../guru-api/models/api-egalaxy.model';
import { GuruApiEGalaxyService } from '../guru-api/guru-api-zoo-egalaxy.service';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { Preferences } from '@capacitor/preferences';
import * as moment from 'moment';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Browser } from '@capacitor/browser';
import { Toast } from '@capacitor/toast';

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss']
})
export class MembershipCardComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  loginLoading: boolean = false;
  // @ViewChild(QRCodeModule) qrcode: QRCodeModule;
  unsubscribe$: Subject<void> = new Subject<void>();
  memberData: EGalaxyTicket = null;
  constructor(private guruApiEgalaxy: GuruApiEGalaxyService, private loadingService: LoadingService, private appDataService: GuruApiAppService) {
    
  }
  item: object = null;
  items: object[] = null;
  lastName: string = '';
  memberId: string = '';
  isLoginModalOpen: boolean = false;
  isDisplayModalOpen: boolean = false;
  ngOnInit(): void {
    console.log("membership init")
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      console.log(state)
      if (!state.show) {
        // this.loadMembership('','');
        
        Preferences.get({key: "membership_id"}).then((value) => {
          console.log(value.value)
          const existingMemberId = value.value;
          if (!existingMemberId) {
            this.loadMembership('','')
          } else {
            Preferences.get({key:'membership_last_name'}).then((value) => {
              const existingLastName = value.value;
              this.loadMembership(existingMemberId, existingLastName);
            })
          }
        })

      }
    });
  }
  loadMembership(visualId: string, lastName: string) {
    console.log(visualId, lastName);
    if (visualId == '' || lastName == '') {

      this.items = this.getItems()
      return
    }
    const member = this.guruApiEgalaxy.getMember();
    if (member) {
      this.memberData = member;
      this.items = this.getItems()
      return
    }
    this.loginLoading = true

    // this.guruApiEgalaxy.getTickets('017032101002168281', 'merritt')
    this.guruApiEgalaxy.getTickets(visualId, lastName)

    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      console.log(data)
      if (data["status_code"]) {
        this.loginLoading = false;
        this.sendToast("Something went wrong, please try again later");
        this.logout();
        this.unsubscribe$.next();

        return
      }
      this.memberData = data;
      this.guruApiEgalaxy.setMember(this.memberData);
      this.items = this.getItems()
      this.sendToast("Login Successful")
      this.loginLoading = false

    }, error => {
      this.loginLoading = false
      console.log("err")
      console.log(error);
      // this.
      this.sendToast("Login Failed")
      this.unsubscribe$.next();
      this.logout();
      // this.modal.dismiss(null, 'close');
      // this.loadMembership('','');
      // this.unsubscribe$.complete();
      
    })
    
  }
  getItems(): object[] {
    const items = [
      {
        "isContent": true,
        "name": "Space Center Houston Membership",
        // "img": "url('../assets/img/MembershipBackground-NoASTC.png')",
        "img": "url('../assets/img/membership-card-background-dark.png')",
        "membershipId": this.memberData?.visual_id_str,
        "passKind": this.memberData?.pass_kind_name,
        "fullname": this.memberData?.first_name+ ' ' + this.memberData?.last_name,
      },
      {
        "name": "Become a Member",
        "img": "url('../assets/img/2023Update_BecomeaMember.jpg')",
        "isContent": false,
        "link": this.appDataService.appDetails.membership_url
      }
    ]
    // if (this.memberData) {
    //   if (this.memberData.pass_kind_name) {
    //     if (this.isASTC()) {
    //       items[0].img = "url('../assets/img/MembershipBackground.png')"
    //     }
    //   }

    // }


    return items
  }
  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm(e) {
    e.preventDefault()
    this.saveMemberPreferences();
    this.toggleModal('login', false)
    this.toggleModal('display', false)

    // this.modal.dismiss(null, 'confirm');
  }
  onWillDismiss(event: Event) {
    console.log(event)
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.saveMemberPreferences();
      // this.message = `Hello, ${ev.detail.data}!`;
      // console.log(ev.detail);

    }
    setTimeout(() => {
      console.log("is modal open? ", this.modal.isOpen)
    }, 200)
  }
  saveMemberPreferences() {
    Preferences.set({
      key: 'membership_id',
      value: this.memberId,
    }).then(() => {
      Preferences.set({
        key: 'membership_last_name',
        value: this.lastName,
      }).then(() => {
        console.log('start login load')
        this.loadMembership(this.memberId, this.lastName);
      })
    })
  }
  getMembersList(): Member[] {
    const memberList = new Array<Member>();
    if (this.memberData?.members.length> 0){
      this.memberData.members.forEach((mb) => {
        memberList.push(mb)
      })
    } else {
      memberList.push({
      first_name: this.memberData.first_name,
      last_name: this.memberData.last_name,
      visual_id_str: this.memberData.visual_id_str,
      primary: true,
    })
    }
    
    
   
    return memberList;
  }
  getFormattedExpirationDate(): string {
    const dx = moment.unix(this.memberData.validated_expiration_date)
    return dx.format('MMM DD, YYYY');
  }
  logout() {
    console.log('start logout');
    Preferences.remove({key: 'membership_last_name'}).then((vx) => {
      Preferences.remove({key: 'membership_id'}).then((vx) => {
        this.memberId = '';
        this.memberData = null;
        this.lastName = ''
        this.items = [];
        this.modal.dismiss(null, 'cancel');
        this.sendToast("Logout Complete")
        this.guruApiEgalaxy.clearMember();
        // this.ngOnInit();
        this.loadMembership('','')
      })
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  handleLink(linkItem: string) {
    console.log("linkItem: " + linkItem)
    Browser.open({ url: linkItem })
  }
  isASTC(): boolean {
    if (this.memberData == null) {
      return false
    }
    return this.memberData.pass_kind_name == "Patron" || this.memberData.pass_kind_name.includes("Crew") || this.memberData.pass_kind_name.includes("Innovation") ||
    this.memberData.pass_kind_name == "Friends and Family" || this.memberData.pass_kind_name == "Supporter" || this.memberData.pass_kind_name == "Contributor"
   }
  async sendToast(msg: string) {
    await Toast.show({
      text: msg
    })
  }
  toggleModal(id: string, state: boolean) {
    switch (id) {
      case "login":{
        this.isLoginModalOpen = state;
        break;
      } 
      case "display": {
        this.isDisplayModalOpen = state;
        break;
      }
    }
  }
}
