<div class="container-fluid" #container>
    <div class="detail-image-display">
        <img [src]="news.default_image.original">
    </div>
    <div class="detail-title">
        <p>{{news.title}}</p>
    </div>
    <div class="detail-description">
        <!-- <p class="subtitle">Description</p> -->
        <p [innerHTML]="news.description"></p>
    </div>
</div>