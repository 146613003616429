import {Injectable, Injector} from '@angular/core';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import { InfoModel } from './models/info.model';
@Injectable({
    providedIn: 'root'
})
export class GuruApiInfoService extends BaseApiService {
    infos: object = {};
    infosList: InfoModel[] = [];

    constructor(private injector: Injector) {
        super(injector, 'info/');
    }
    loadInfos(): Observable<ApiListResponseModel<InfoModel>> {
        return super.getList<InfoModel>();
    }
    loadInfo(id): Observable<InfoModel> {
        const response = super.getDetail<InfoModel>(id);
        response.subscribe(
            (data: InfoModel) => {
                this.infos[id] = data;
            }, error => {
                console.log('Error loading data: ', error);
            }
        );
        return response;
    }
    getInfos(): InfoModel[] {
        return this.infosList;
    }

    setInfos(infos: InfoModel[]): void {
        this.infosList = infos;
        this.infosList.forEach((ev: InfoModel) => {
            this.infos[ev.id] = ev
        })
    }

    getInfo(infoId): InfoModel {
        return this.infos[infoId];
    }

    resetInfos(): void {
        this.infosList =[];
        this.infos = {};
    }
    clearData(): void {
        this.resetInfos();
    }

}