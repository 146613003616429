import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from '../loading/loading.service';
import { catchError, finalize, map, retry, takeUntil } from 'rxjs/operators';
import { HttpCancelServiceService } from '../http-cancel-service/http-cancel-service.service';
import { LanguageHolderService } from 'src/app/services/language-holder.service';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {
  requestCount = 0;

  constructor(private cookieService: CookieService,
              private loadingService: LoadingService,
              private httpCanclelService: HttpCancelServiceService,
              private langService: LanguageHolderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCount++;
    // this.loadingService.show();

    // const langCode = this.cookieService.get('language');
    const langCode = this.langService.getLang()
    console.log("lang: ", langCode)
    // const langCode = this.translate.currentLang
    const modifiedReq = req.clone({
      headers: req.headers.set('language', langCode)
    });
    return next.handle(modifiedReq)
      .pipe(takeUntil(this.httpCanclelService.onCancelPendingRequests()))
      .pipe(catchError((err: any, caught) => {
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        return evt;
      }))
      .pipe(finalize(() => {
        this.requestCount--;
        if (this.requestCount <= 0) {
          this.loadingService.hide();
        }
      }));
  }
}
