import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import {AppConfigService} from '../services/app-config/app-config.service';
import {CookieService} from 'ngx-cookie-service';
import { AppDetailsModel } from '../guru-api/models/app-details.model';
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';
import { ApiManagerService } from '../services/api-management/api-manager.service';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})

export class SettingsPageComponent implements OnInit, OnDestroy {
  langCodes: string[];
  appDetailsSubscription$: Subscription;
  currentLanguage: string;
  privacyURL: string = '';
  tosURL: string = '';
  appVersion: string = '';
  appBuildCode: string = '';
  viewMode: string = '';
  viewModes: string[] = [
    "DARK",
    "LIGHT",
    "SYSTEM"
  ]
  constructor(private guruApiAppService: GuruApiAppService,
              private appConfigService: AppConfigService,
              private cookieService: CookieService,
              private apiManager: ApiManagerService) { }

  ngOnInit(): void {
    this.langCodes = this.appConfigService.getLanguages();
    this.currentLanguage = this.appConfigService.language;
    this.getAppVersion();
    if(!this.langCodes || !this.currentLanguage) {
      this.langCodes = ['en'];
      this.loadDetails();
      this.getViewMode()
    }
  }

  ngOnDestroy(): void {
    if(this.appDetailsSubscription$){
      this.appDetailsSubscription$.unsubscribe();
    }
  }

  getLangName(code: string): string{
    return this.guruApiAppService.getLangName(code);
  }

  onSelectChange(code: string): void {
    this.currentLanguage = code;
    this.appConfigService.language = this.currentLanguage;
    this.cookieService.set('language', this.currentLanguage);
    Preferences.set({
      key:'language',
      value: this.currentLanguage
    }).then(() => {
      this.apiManager.clearCurrentData();
    })
  }

  initLanguage(appDetails: AppDetailsModel): void{
    this.langCodes = [...this.langCodes, ...appDetails.languages];
    this.langCodes = [...new Set(this.langCodes)];
    this.appConfigService.setLanguages(this.langCodes);
    if (this.guruApiAppService.deviceInfo == 'web') {
      console.log("is web")
      if(this.cookieService.check('language')) {
        this.currentLanguage = this.cookieService.get('language');
      } else {
        this.currentLanguage = this.langCodes[0];
        this.cookieService.set('language', this.currentLanguage);
      }
    } else {
      Preferences.get({
        key: 'language'
      }).then((langVal) => {
        console.log("langval", langVal.value)
        if (langVal.value) {
          // this.onSelect(langVal.value)
          this.currentLanguage = langVal.value
        } else {
          this.currentLanguage = this.langCodes[0];
        }
      })
    }

    
  }

  loadDetails(): void {
    const appDetails = this.guruApiAppService.getAppDetails();
    if(appDetails){
      console.log("dets")
      console.log(appDetails)
      this.initLanguage(appDetails);
      this.privacyURL = appDetails.privacy_policy.url;
      this.tosURL = appDetails.terms_of_service.url
      return;
    }
    this.appDetailsSubscription$ = this.guruApiAppService.loadAppFullDetails().subscribe(
      data => {
        this.initLanguage(data);
        console.log(data)
        this.privacyURL = data.privacy_policy.url;
        this.tosURL = data.terms_of_service.url
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      }
    );
  }
  async getAppVersion() {
    console.log(this.guruApiAppService.deviceInfo)
    if (this.guruApiAppService.deviceInfo == 'web') {

    } else {
      console.log("get app version")
      const info = await App.getInfo()
      // console.log("info", info)
      this.appVersion = info.version;
      this.appBuildCode = info.build
    }

  }
  async openLink(targetLink: string) {
    await Browser.open({
      url: targetLink
    })
  }
  async getViewMode() {
    await Preferences.get({
      key: 'view-mode'
    }).then((value) => {
      if (value.value) {
        this.viewMode = value.value
      } else {
        this.viewMode = Style.Dark
      }
    })
  }
  async onViewModeChange(mode: string) {
    console.log(mode)
    await Preferences.set({
      key: "view-mode",
      value: mode
    }).then(() => {
      this.viewMode = mode
      switch (mode) {
        case "DARK": {
          StatusBar.setStyle({ style: Style.Dark });
          break;
        }
        case "LIGHT": {
          StatusBar.setStyle({ style: Style.Light });
          break;
        }

      }

    })
  }
}
