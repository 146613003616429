<div class="container-fluid" *ngIf="categoryItems">
  <div class="tour-header col-12"
       [style.background-image]="'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #000000 97.92%), url(' + category?.background_photo?.big_retina + ')'">
    <h1 class="align-bottom">{{category.name}}</h1>
  </div>
  <div class="content-section">
    <app-tour-stops [text]="'category-details-page.category-items' | translate" [items]="categoryItems"></app-tour-stops>
  </div>
  <div class="spacer"></div>
</div>
