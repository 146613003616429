import { Component, Input, OnInit } from '@angular/core';
import { EventItemModel, EventResponseModel } from '../guru-api/models/events.model';
import { Subject, Subscription } from 'rxjs';
import { GuruApiEventService } from '../guru-api/guru-api-events.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {Router} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";

@Component({
  selector: 'app-event-carousel',
  templateUrl: './event-carousel.component.html',
  styleUrls: ['./event-carousel.component.scss']
})
export class EventCarouselComponent implements OnInit {
  @Input() todayOnly?: boolean = false;
  events: EventResponseModel[];
  unsubscribe$: Subject<void> = new Subject<void>();
  loading: boolean = false;
  constructor(private guruApiEventService: GuruApiEventService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loading = true
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadEvents();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadEvents(): void {
    const events = this.guruApiEventService.getEvents();
    if (events && events.length) {
      // this.filterTours(tours);
      // this.events = events.slice(0, 10);
      this.filterData(events);
      return;
    }

    this.guruApiEventService.loadEvents()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        this.filterData(data.objects)
      }
    )
  }
  filterData(eventsData: EventResponseModel[]) {
    let eventList = [];
    if (this.todayOnly) {
      eventList = eventsData.filter(e => {
        return moment.unix(e.start_time) > moment() && e.end_time < moment().add(1, 'd').unix();
      })
    } else {
      eventList = eventsData.filter(e => e.start_time >= new Date().getSeconds());
    }
    // const eventList = 
    eventList.sort((a, b)=> a.start_time - b.start_time)
    this.loading = false
    this.events = eventList;
    this.guruApiEventService.setEvents(eventsData);
  }
  formatDate(toParse: number): string {
    var setDate = moment.unix(toParse);
    if (this.todayOnly) {
      return setDate.calendar();
    }
    return setDate.calendar() + " " + setDate.format("LT");
  }
  goToEvents() {
    this.router.navigate(['events']);
  }
  goToEvent(id: number, start_time: number) {
    this.router.navigate(["events/" + id], {
      queryParams: {
        start_time: start_time
      }
    })
  }
}
