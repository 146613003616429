import {Injectable, Injector} from '@angular/core';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {ApiListResponseModel} from './models/api-list-response.model';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {EGalaxyTicket} from './models/api-egalaxy.model'
@Injectable({
    providedIn: 'root'
})
export class GuruApiEGalaxyService extends BaseApiService {
    member: EGalaxyTicket = null;
    constructor(private injector: Injector) {
        super(injector, "/egalaxy/tickets", '', environment.apiRoot+'zoo/v1')
    }
    getTickets(visual_id: string, last_name: string): Observable<EGalaxyTicket> {
        return super.getDetailwithParams({visual_id: visual_id,last_name: last_name,format: 'json', app_id: environment.appId})
    }
    getMember(): EGalaxyTicket {
        return this.member
    }
    setMember(memberData: EGalaxyTicket): void {
        this.member = memberData;
    }
    clearMember() {
        this.member = null;
    }
}
