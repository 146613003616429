import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiListResponseModel } from '../models/api-list-response.model';
import { SiteSectionModel } from '../models/site-section.model';

@Injectable()
export class BaseApiService {
  url: string;
  urlExt: string;
  http: HttpClient;
  queryParams: string[];
  apiUrlOverride: string = '';

  constructor(private injectorObj: Injector, url: string, urlExt: string = '', apiRootOverride = '') {
    this.http = this.injectorObj.get(HttpClient);
    this.url = url;
    this.urlExt = urlExt;
    this.apiUrlOverride = apiRootOverride;
  }

  /**
   * Lists
   */
  getList<T>(params?): Observable<ApiListResponseModel<T>> {
    console.log(this.url)
    const targetRootURL = this.apiUrlOverride==''?environment.apiUrl:this.apiUrlOverride
    return this.http.get<ApiListResponseModel<T>>(targetRootURL + this.url +
      '?limit=1000&app=' + environment.appId + '&timestamp=' + Date.now(),
      { params });
  }

  /**
   * Details
   */
  getDetail<T>(id): Observable<T> {
    return this.http.get<T>(environment.apiUrl + this.url + id + this.urlExt +
      '?limit=1000&app=' + environment.appId + '&timestamp=' + Date.now());
  }
  getDetailRoot<T>(id): Observable<T> {
    return this.http.get<T>(environment.apiUrl + this.url + id +
      '?limit=1000&app=' + environment.appId + '&timestamp=' + Date.now());
  }
  getDetailwithParams<T>(params?):Observable<T> {
    const targetRootURL = this.apiUrlOverride==''?environment.apiUrl:this.apiUrlOverride
    return this.http.get<T>(targetRootURL + this.url +
      '?limit=1000&app=' + environment.appId + '&timestamp=' + Date.now(),
      { params });
  }

  getDetailsByDomain<T>(domain): Observable<ApiListResponseModel<T>> {
    return this.http.get<ApiListResponseModel<T>>(environment.apiUrl + this.url + domain + this.urlExt
      + '&limit=1000' + '&timestamp='+ Date.now());
  }

}
