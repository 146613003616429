import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-dots',
  templateUrl: './nav-dots.component.html',
  styleUrls: ['./nav-dots.component.scss']
})
export class NavDotsComponent implements OnInit {
  @Input() count = 0;
  @Input() selectedIndex = 0;
  @Input() activeColor = "white";
  @Input() inactiveColor = "grey"

  constructor() { }

  ngOnInit(): void {
  }
  itemCount(): number[] {
    return Array(this.count).fill(0).map((x, i) => i)
  }
}
