import {Injectable, Injector} from '@angular/core';
import {TourModel} from './models/tour.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import { VirtualTourModel } from './models/virtual-tour.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuruApiVirtualToursService extends BaseApiService {
  tours: object = {};
  toursList: VirtualTourModel[] = null;

  constructor(private injector: Injector) {
    super(injector, 'virtual_tour/');
  }

  loadVirtualTours(): Observable<VirtualTourModel> {
    return super.getDetailwithParams({app_id: environment.appId});
  }

  loadVirtualTour(id): Observable<VirtualTourModel> {
    const response = super.getDetail<VirtualTourModel>(id);
    response.subscribe(
      (data: VirtualTourModel) => {
        this.tours[id] = data;
      }, error => {
        console.log('Error loading data: ', error);
      });
    return response;
  }

  getVirtualTours(): VirtualTourModel[] {
    return this.toursList;
  }

  setVirtualTours(tours: VirtualTourModel[]): void{
    this.toursList = tours;
  }

  getTour(tourId): VirtualTourModel {
    return this.tours[tourId];
  }

  resetTours(): void{
    this.toursList = null;
    this.tours = {};
  }
  clearData(): void {
    this.resetTours();
  }

}
