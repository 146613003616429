import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisplaySettingsService } from '../services/display-settings/display-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {
  museum_logo: string;
  displaySettingsSubscription$: Subscription;

  constructor(private displaySettingsService: DisplaySettingsService) { }

  ngOnInit(): void {
    this.displaySettingsSubscription$ = this.displaySettingsService.getConfig().subscribe(config => {
      if (config.museumLogo && config.museumLogo.length) {
        this.museum_logo = config.museumLogo;
      }
    });
  }

  ngOnDestroy(): void {
    this.displaySettingsSubscription$ && this.displaySettingsSubscription$.unsubscribe();
  }
}
