import { Component, OnInit } from '@angular/core';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-more-info-page',
  templateUrl: './more-info-page.component.html',
  styleUrls: ['./more-info-page.component.scss']
})
export class MoreInfoPageComponent implements OnInit {

  constructor(private guruAppDetailService: GuruApiAppService, private loadingService: LoadingService) { }
  unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadAppDetails()
      }
    });
  }
  loadAppDetails() {
    const appDetails = this.guruAppDetailService.appDetails;
    if (appDetails.opening_hours) {
      return;
    }
    this.guruAppDetailService.loadAppFullDetails()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.guruAppDetailService.setAppDetails(data);
    })
  }

}
