import { registerPlugin } from "@capacitor/core";
import { EventData } from "src/definitions";

export interface FirebasePlugin {
    logEvent(options: {data: EventData}): Promise<any>;
    logPageView(options: {pageName: string}): Promise<any>;
}

const Firebase = registerPlugin<FirebasePlugin>('Firebase',
    {
        web: () => import('../../web').then(m => new m.FirebaseWeb()),
    }
);
export default Firebase;