import { Component, OnInit, EventEmitter , Output} from '@angular/core';
import PermissionsManager from '../plugins/permissions';
import { LoadingService } from '../services/loading/loading.service';
@Component({
  selector: 'app-permissions-screen',
  templateUrl: './permissions-screen.component.html',
  styleUrls: ['./permissions-screen.component.scss']
})
export class PermissionsScreenComponent implements OnInit {
  show: boolean = true;
  stepIndex: number = 0;
  opacity: number = 100;
  @Output() screenComplete: EventEmitter<any> = new EventEmitter();
  constructor(private loading: LoadingService) { }

  ngOnInit(): void {
    // () => {
      this.loading.show()
      // this.loading.appState.
      this.checkExistingPerms();
    // }z
  }
  checkExistingPerms() {
    PermissionsManager.checkPermission({value: "location"}).then((locPerm) => {
      console.log(locPerm.toString())
      if (locPerm["location"] != "prompt") {
        this.stepIndex++;
      } else {
        this.loading.hide()
        return
      }
      PermissionsManager.checkPermission({value:"bluetooth"}).then((bluePerm) => {
        if (bluePerm["bluetooth"] != "prompt") {
          this.stepIndex++;
        }
        this.loading.hide()
        if (this.stepIndex > this.steps().length-1) {
          // this.show = false;
          // this.opacity = 0;
          this.screenComplete.emit('done')
        }
    })
    
    })
    
  }
  steps(): object[] {
    return [
      
      {
        "header": "Find your way around",
        "text": "Enable Geolocation to interact with live mapping to find your way around.",
        "permission_name": "location"
      },
      {
        "header": "Interact with your Space",
        "text": "Enable Bluetooth to receive valuable updates, interative wayfinding, and improve the overall visitor experience.",
        "permission_name": "bluetooth"
      },
    ]
  }
  async requestPermission() {
    const permName = this.steps()[this.stepIndex]['permission_name']
    const ret = await PermissionsManager.requestPermission({ value: permName})
    console.log(ret)
    if (ret[permName] == "prompt") {
      return
    }
    this.stepIndex++;
    if (this.stepIndex > this.steps().length-1) {
      // this.show = false;
      // this.opacity = 0;
      this.screenComplete.emit('done')
    }
  }
  skip() {
    this.stepIndex++;
    if (this.stepIndex > this.steps().length-1) {
      // this.show = false;
      // this.opacity = 0;
      this.screenComplete.emit('done')
    }
  }
}
