<app-generic-carousel
    [rowTitle]="'news-carousel.title'"
    [items]="news"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item>
    <div class="col" (click)="openTramSite()">
        <img [src]="item.default_image.thumb_manager" class="carousel-item-image-single"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.title}}</p>
            </div>

        </div>
    </div>
</ng-template>