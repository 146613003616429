import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loaderSubject = new BehaviorSubject<any>(<any>{show: true});
  loaderState = this.loaderSubject.asObservable();
  appState = new BehaviorSubject(false);
  noInternet = false;
  constructor() {
  }

  show(): void {
    this.loaderSubject.next(<any> {show: true});
  }

  hide(): void {
    this.loaderSubject.next(<any> {show: false});
  }
  noConnection(): void {
    console.log("no internet");
    this.noInternet = true;
  }
  // loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // loadingMap: Map<string, boolean> = new Map<string, boolean>();
  // loadingAppInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  //
  // constructor() { }
  //
  // setLoading(loading: boolean, url: string): void{
  //   if(!url){
  //     throw new Error('URL cannot be empty');
  //   }
  //
  //   if(loading){
  //     this.loadingMap.set(url, loading);
  //     this.loadingSub.next(true);
  //   } else if (!loading && this.loadingMap.has(url)){
  //     this.loadingMap.delete(url);
  //   }
  //
  //   if(this.loadingMap.size === 0){
  //     this.loadingSub.next(false);
  //   }
  // }
}
