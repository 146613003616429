import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable() 
export class NavApiService {
    url: string
    http: HttpClient

    constructor(private injectorObj: Injector, url: string) {
        this.http = this.injectorObj.get(HttpClient)
        this.url = url
    }

    post<T>(body, params?): Observable<T> {
        const targetUrl = environment.navApi + this.url
        return this.http.post<T>(targetUrl, body)
    }
}