<!-- <p>events-item-page works!</p> -->
<div class="container-fluid">
    <div *ngIf="eventDef != null" class="event-item">
        <div class="image-holder">
            <img class="event-img" [src]="getImage()"/>
        </div>
        <div class="event-label">
            <span>
                {{eventDef.event.name}}
            </span>
            <button>
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
            </button>
        </div>
        <div class="event-timing">
            <span class="event-date">
                {{formatDisplayDate()}}
            </span>
            <span class="event-time">
                {{formatEventDuration()}}
            </span>
        </div>
        <div>
            <p [innerHTML]="eventDef.event.details.description">
               
            </p>
        </div>
        <div>
            <button class="cal-button" (click)="addToCal()">Add To Calendar</button>
        </div>
    </div>
</div>