import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {
  loading = false;
  private subscription: Subscription;

  constructor(
    private loaderService: LoadingService
  ) {
  }

  ngOnInit() {
    this.loading = false;
    this.subscription = this.loaderService.loaderState
      .subscribe((state: boolean) => {
        this.loading = state['show'];
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  // loading = false;
  //
  // constructor(private loadingService: LoadingService) {
  // }
  //
  // ngOnInit(): void {
  //   this.listenToLoading();
  // }
  //
  //
  // listenToLoading(): void {
  //   this.loadingService.loadingSub
  //     .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
  //     .subscribe((loading) => {
  //       this.loading = loading;
  //     });
  // }
}
