import { Component, Input, OnInit } from '@angular/core';
import { PhotoModel } from '../../guru-api/models/photo.model';
import { VideoModel } from '../../guru-api/models/video.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-gallery-item-modal',
  templateUrl: './gallery-item-modal.component.html',
  styleUrls: ['./gallery-item-modal.component.scss']
})
export class GalleryItemModalComponent implements OnInit {
  @Input() photoObj: PhotoModel[];
  @Input() videoObj: VideoModel[];
  @Input() index: number;

  url: string;
  length: number;
  caption?: string;

  animNext = true; // simulates initialization to prevent showing ui items before item is loaded
  animPrev = true; // Note for spinner: (animNext || animPrev) && !(animNext && animPrev) if both flags are set to true
                   // then don't show spinner (both flags are only set to true on init)

  swipeCoord?: [number, number];
  swipeTime?: number;

  constructor(private modalService: NgbModal,
              private modalRef: NgbActiveModal) { }

  ngOnInit(): void {
    this.getData();
  }

  dismiss(): void{
    this.modalRef.dismiss();
  }

  close(): void{
    this.modalRef.close();
  }

  getData(): void{
    if(this.photoObj?.length){
      this.url = this.photoObj[this.index].image.full;
      this.length =  this.photoObj.length;
      this.caption = this.photoObj[this.index]?.caption;
      return;
    }
    let url = this.videoObj[this.index].video.video;
    if(url.search('embed') < 0){
      url = this.videoObj[this.index].video.video.replace('watch?v=', 'embed/');
      url = url.substr(0, url.indexOf('&'));
    }
    this.url = url;
    this.length = this.videoObj.length;
  }

  next(): void{
    if(this.index < this.length - 1) {
      this.index++;
      this.getData();
      this.animNext = true;
    }
  }

  prev(): void{
    if(this.index){
      this.index--;
      this.getData();
      this.animPrev = true;
    }
  }

  onLoad(): void{
    this.animNext = this.animPrev = false;
  }

  swipe(e: TouchEvent, when: string): void{
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if(when === 'start'){
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end'){
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 60
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
          const swipe = direction[0] < 0 ? 'next' : 'previous';
          if(swipe === 'next'){
            this.next()
            return;
          }
          this.prev();
      }
    }
  }
}
