import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {TourModel} from '../guru-api/models/tour.model';
import {Router} from '@angular/router';
import { CategoryItemModel } from '../guru-api/models/category-item.model';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { TourStopModel } from '../guru-api/models/tour-stop.model';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-tour-stops',
  templateUrl: './tour-stops.component.html',
  styleUrls: ['./tour-stops.component.scss']
})
export class TourStopsComponent implements OnInit {
  @Input() text: string;
  @Input() items: TourItemModel[] | CategoryItemModel[];
  @Input() tourInfo: string;
  @Input() tourId?: number;

  constructor(private router: Router, private navState: NavService) {
  }

  ngOnInit(): void {
    console.log(this.items.length)
    
  }
  ngOnChanges(changes: SimpleChanges) {

    // console.log(changes)
    if (!changes.items.previousValue && changes.items.currentValue.length > 0) {
      const lastState = this.navState.getPageData("category")
      if (lastState) {
        console.log(this.items.length)
        setTimeout(() => {
          this.scrollToItem(lastState.returnIndex)
        }, 100)
      }
    }
    //

}

  redirectTo(id: number): void{
    if(this.items[id].hasOwnProperty('item')){
      this.router.navigate(['tourStop/'+ this.tourId + '/' + id]);
      return;
    }
    this.navState.setPageData("category", {
      returnIndex: id
    })
    this.router.navigate(['item/'+ this.items[id].id]);
  }
  scrollToItem(id) {
      const targetEl = document.getElementById("stopInd-" + id)
      targetEl.scrollIntoView({behavior: "smooth", block: "center"})
  }
}
