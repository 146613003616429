<div class="map-container">
  <!-- <h1 class="text-center" style="margin-top: 0px; margin-bottom: 5px;">{{'maps-page.title' | translate | titlecase}}</h1> -->
  <div class="map-display">
    <app-arcmap [points]="sitePoints" (pointClick)="pointClick($event)" [navPoints]="navPoints" [inNavRange]="inRange" [externalSelect]="selectedPoint" (endNavLoop)="clearNavEmit()"></app-arcmap>
  </div>
  <!-- <div class="row map-items" *ngFor="let map of map_items; let i = index" (click)="goToMap(i)">
    <span class="w-100">{{map.name | uppercase}}</span>
    <img src="{{getMapUrl(i)}}" alt="{{map.name}}" class="img-fluid w-100" >
  </div> -->
  <!-- <div class="spacer"></div> -->
  <div class="info-display">
    <div id="scrollbox">
      <div *ngFor="let mx of mapListings()" class="map_listing" [id]="mx.order">
        <div class="content" (click)="isolatedListing < 0 && listingClick(mx.id)" >
          <div class="col">
            <img [src]="mx.list_page_image.thumb_manager" class="map-image"/>
          </div>
          <div class="col">
           <div class="title">
            {{mx.order}}: {{mx.name}}
           </div>
    
          </div>
        </div>
        <div class="button-box" *ngIf="isolatedListing > -1">
          <ion-button (click)="isolatedSeeMore()">Learn More</ion-button>
          <ion-button (click)="isolatedGoHere()">Go Here</ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
