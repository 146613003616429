import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageHolderService {
  private currentLanguage = ""
  constructor() { }
  setLang(lang: string) {
    this.currentLanguage = lang
  }
  getLang(): string {
    return this.currentLanguage
  }
}
