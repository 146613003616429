<div class="bottom-menu">
  <button
        class="new-btn home-new-button"
        (click)="onSelect('home')"
    [class.active]="active === 'home'"
    routerLink="home"
  >
        <img src="../../assets/img/home-icon-inner.svg"/>
  </button>
  <!-- <button
    class="home-button btn-default"
    (click)="onSelect('home')"
    [class.btn-active]="active === 'home'"
    routerLink="home"
  ></button> -->
  <button
        class="new-btn search-new-button"
        (click)="onSelect('keypad')"
    [class.active]="active === 'keypad' || active === 'search'"
    routerLink="search"
  >
        <img src="../../assets/img/keypad-icon-white.svg"/>
  </button>
  <button
        class="new-btn map-new-button"
        (click)="onSelect('maps')"
    [class.active]="active === 'maps'"
    routerLink="maps"
  >
        <img src="../../assets/img/location-white.svg" style="height: 40px;"/>
  </button>
  <button
        class="new-btn more-new-button"
        (click)="onSelect('moreinfo')"
    [class.active]="active === 'moreinfo'"
    routerLink="moreinfo"
  >
        <img src="../../assets/img/more-icon-white.svg"/>
  </button>
  <!-- <button
    class="keypad-button btn-default"
    (click)="onSelect('keypad')"
    [class.btn-active]="active === 'keypad'"
    routerLink="search"
  ></button> -->
  <!-- <button
    class="map-button btn-default"
    (click)="onSelect('map')"
    [class.btn-active]="active === 'map'"
    routerLink="maps"
  ></button> -->
  <!-- <button class="nav-button"
          (click)="onSelect('nav')"
  >
    <span class="nav-arrow btn-default"
          [class.btn-active]="active==='nav'"
          routerLink="home"
    >
    </span>
  </button> -->

  <!-- <button
    class="more-button btn-default"
    (click)="onSelect('more')"
    [class.btn-active]="active === 'more'"
  ></button> -->
</div>
