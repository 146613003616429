import {Injectable, Injector} from '@angular/core';
import {EventResponseModel, EventItemModel} from './models/events.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class GuruApiEventService extends BaseApiService {
    events: object = {};
    eventsList: EventResponseModel[] = null;
    constructor(private injector: Injector) {
        super(injector, 'eventtime/');
    }
    loadEvents(currentMonth: number = moment().month() + 1): Observable<ApiListResponseModel<EventResponseModel>> {
        // const currentMonth = 
        return super.getList<EventResponseModel>({in_progress: moment().year() + "-" + currentMonth + "-1"});
    }
    loadEvent(id): Observable<EventResponseModel> {
        const response = super.getDetail<EventResponseModel>(id);
        response.subscribe(
            (data: EventResponseModel) => {
                this.events[id] = data;
            }, error => {
                console.log('Error loading data: ', error);
            }
        );
        return response;
    }
    getEvents(): EventResponseModel[] {
        return this.eventsList;
    }

    setEvents(events: EventResponseModel[]): void {
        this.eventsList = events;
        this.eventsList.forEach((ev: EventResponseModel) => {
            if (this.events[ev.event.id]) {
                this.events[ev.event.id].push(ev)
                // console.log("exists: ", this.events[ev.event.id], "new: ",)
            } else {
                this.events[ev.event.id] = [ev]
            }
        })
    }

    getEvent(eventId): EventResponseModel[] {
        return this.events[eventId];
    }

    resetEvents(): void {
        this.eventsList = null;
        this.events = {};
    }
    clearData(): void {
        this.resetEvents();
    }

}