import {Injectable, Injector} from '@angular/core';
import {EventResponseModel, EventItemModel, EventDetail} from './models/events.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class GuruApiEventDetailService extends BaseApiService {
    constructor(private injector: Injector) {
        super(injector, 'event/');
    }
    loadEvent(id): Observable<EventDetail> {
        return super.getDetail<EventDetail>(id)
    }
}