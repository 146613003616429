import {Injectable, Injector} from '@angular/core';
import {TourModel} from './models/tour.model';
import {BaseApiService} from './base/base-api.service';
import {Observable} from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import { TourItemModel } from './models/tour-item.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiTourItemsService extends BaseApiService {
  items: object = {};

  constructor(private injector: Injector) {
    super(injector, 'item/');
  }

  loadTourItem(id: number): Observable<TourItemModel> {
    return super.getDetail<TourItemModel>(id);
  }

  getTourItem(id: number): TourItemModel {
    return this.items[id];
  }
  clearData(): void {
    this.items = null;
  }

}
