import { Component, OnInit, Input } from '@angular/core';
import { InfoModel } from 'src/app/guru-api/models/info.model';
@Component({
  selector: 'app-info-detail-display',
  templateUrl: './info-detail-display.component.html',
  styleUrls: ['./info-detail-display.component.scss']
})
export class InfoDetailDisplayComponent implements OnInit {
  @Input() info: InfoModel;
  
  constructor() { }

  ngOnInit(): void {
  }

}
