import { Component, OnInit } from '@angular/core';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { Browser } from '@capacitor/browser';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-tickets',
  templateUrl: './buy-tickets.component.html',
  styleUrls: ['./buy-tickets.component.scss']
})
export class BuyTicketsComponent implements OnInit {

  constructor(private guruAppDetailService: GuruApiAppService, private loadingService: LoadingService, private router: Router) { }
  unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadAppDetails()
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  async loadAppDetails() {
    const appDetails = this.guruAppDetailService.appDetails;
    if (appDetails.buy_tickets_url) {
      await Browser.open({url: appDetails.buy_tickets_url})
      Browser.addListener('browserFinished', () => {
        this.router.navigate(['/moreinfo'], { replaceUrl: true })
      })
      return;
    } else {
      this.guruAppDetailService.loadAppFullDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => async() => {
        this.guruAppDetailService.setAppDetails(data);
        await Browser.open({url: data.buy_tickets_url});
        Browser.addListener('browserFinished', () => {
          this.router.navigate(['/moreinfo'], { replaceUrl: true })
        })
      })
    }
    
  }

}
