<div class="container-fluid">
    <div class="detail-image-display">
        <img [src]="info.banner_image.original">
    </div>
    <div class="detail-title">
        <p>{{info.title}}</p>
    </div>
    <div class="detail-description">
        <!-- <p class="subtitle">Description</p> -->
        <p [innerHTML]="info.description"></p>
    </div>
</div>