<div class="container-fluid">
    <h2>{{'more-info-page.more-info' | translate | titlecase}}</h2>
    <div class="info-box-list">
        <div class="box-row row">
            <div id="important-info" class="info-box" routerLink="/settings">
                <div class="icon-block">
                    <i class="fas fa-cog"></i>
                </div>
                <span>{{'more-info-page.settings' | translate | titlecase}}</span>
            </div>
            <div id="admission" class="info-box" routerLink="tickets">
                <div class="icon-block">
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <span>{{'more-info-page.admission' | translate | titlecase}}</span>
            </div>
        </div>
        <div class="box-row row" >
            <div id="museum-hours" class="info-box" routerLink="hours">
                <div class="icon-block">
                    <i class="fas fa-clock"></i>
                </div>
                <span>{{'more-info-page.hours' | translate | titlecase}}</span>
            </div>
            <div id="museum-info" class="info-box" routerLink="/about-us">
                <div class="icon-block">
                    <!-- <ion-icon name="information-circle"></ion-icon> -->
                    <!-- <ion-icon src="../../assets/img/information-circle.svg"></ion-icon> -->
                    <i class="fas fa-info-circle"></i>
                </div>
                <span>{{'more-info-page.info' | translate | titlecase}}</span>
            </div>
        </div>
    </div>
</div>