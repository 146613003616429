// Usage:
// Inject more-menu service into component
// Add [ngStyle] = "getState() && {'filter' : 'blur(5px)'}" to html div
// <div [ngStyle] = "getState() && {'filter' : 'blur(5px)'}">
//    ...
// </div>
import {Component, OnInit} from '@angular/core';
import {MoreMenuStateService} from '../services/more-menu-state.service';

@Component({
  selector: 'app-more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.scss']
})
export class MoreMenuComponent implements OnInit {

  constructor(private moreMenuState: MoreMenuStateService) {
  }

  ngOnInit(): void {
  }

  getState(): boolean {
    return this.moreMenuState.getState();
  }

  setState(state: boolean): void {
    this.moreMenuState.setState(state);
  }

}
