<div class="container-fluid h-100" *ngIf="tourObj">
  <app-item-details [stop]="getItem()"></app-item-details>
  <div class="row button-container">
    <!--<button class="prev-stop" [disabled]="isFirstStop()" [ngClass]="isFirstStop() && 'disabled-button'"-->
    <button class="prev-stop"
            (click)="prevStop()">
      <!--Previous Stop-->
      {{'tour-stop-page.back-to-list' | translate | titlecase}}
    </button>
    <button class="next-stop"
            [disabled]="isLastStop()"
            [ngClass]="isLastStop() && 'disabled-button'"
            (click)="nextStop()">
      {{'tour-stop-page.next-stop' | translate | titlecase}}
    </button>
  </div>
</div>


