<div class="container-fluid" *ngIf="item">
  <div class="image-holder">
    <!-- <img class="item-img" [src]="item?.image.original"/> -->
    <app-generic-carousel
    [items]="imageList()"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item >
    <div >
        <img [src]="item.original" class="carousel-item-image-single item-img"/>
        <!-- <div class="item-info">
            <div class="item-name">
                <p>{{item.info_name}}</p>
            </div>

        </div> -->
    </div>
</ng-template>
  </div>
  <mat-slider [min]="0"
  [max]="state ? state.duration : 0"
  [step]="1"
  (change)="onSliderChangeEnd($event)"
  [value]="state?.currentTime"
>
</mat-slider>
  <!-- <div class="stop-header col-12"
       [style.background-image]="'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #F5F5F5 97.92%), url(' + item.image.full + ')'">
  </div> -->
  <h1>{{getName()}}</h1>
  <div class="button-row">
    <div class="activity-button" *ngFor="let btn of btnList" (click)="buttonHandler(btn.name)">
      <i [class]="btn.icon"></i>
    </div>
  </div>
  <div class="stop-audio" *ngIf="state.canplay">
    <button class="play-button" (click)="play()" *ngIf="!state.playing"></button>
    <button class="pause-button" (click)="pause()" *ngIf="state.playing"></button>
    <mat-slider class="slider" [min]="0"
                [max]="state ? state.duration : 0"
                [step]="1"
                (change)="onSliderChangeEnd($event)"
                [value]="state?.currentTime"
    >
    </mat-slider>
    <div class="duration">{{state.readableCurrentTime??""}} / {{state ? state.readableDuration : "00:00"}}</div>
  </div>
  <!-- <div class="feature-buttons">
    <button (click)="toggleGallery()" *ngIf="item?.photos?.length" class="mr-4"><i class="far fa-images" [style]="showGallery ? 'color: var(--primary-color)' : 'color: black'" ></i></button>
    <button (click)="toggleVideos()" *ngIf="item?.videos?.length"><i class="fab fa-youtube" [style]="showVideos ? 'color: var(--primary-color)' : 'color: black'"></i></button>
  </div> -->
  <app-image-gallery
    [text]="'image-gallery.images' | translate"
    [photoObj]="item?.photos"
    [show]="showGallery">
  </app-image-gallery>
  <app-image-gallery
    [text]="'image-gallery.videos' | translate"
    [videoObj]="item?.videos"
    [show]="showVideos">
  </app-image-gallery>

  <div class="stop-content-section">
    <div class="stop-description col-12" [innerHTML]="item.description"></div>
  </div>
  <div class="spacer"></div>
</div>
<ion-modal [isOpen]="openModal" (didPresent)="modalOpen($event)">
  <ng-template>
      <ion-header>
          <ion-toolbar>
              <ion-buttons>
                  <ion-button (click)="closeViewer()">Close</ion-button>
              </ion-buttons>
          </ion-toolbar>
      </ion-header>
      <ion-content>
          <div id="panoViewer" style="position: relative; height: 100%; width: 100%;"></div>
          <!-- <ngx-pano-viewer [image]="imgSrc" style="position: relative; height: 200px; width: 200px;"></ngx-pano-viewer> -->
      </ion-content>
  </ng-template>
</ion-modal>
<div id="pano-holder"></div>