<div *ngIf="show" [@inAndOutAnimation]>
  <div class="container-fluid" *ngIf="photoObj || videoObj">
    <div class="row mt-4 mb-3 title-header">
      <h3>{{(text || '') | uppercase}}</h3>
    </div>

    <div class="row image-container">
        <div class="image col-3 " *ngFor="let object of getObjects(); let i = index" (click)="showItem(i)">
          <img class="img-fluid" [src]="getUrl(i)">
          <div class="play-icon" *ngIf="videoObj && !photoObj?.length"><i class="fab fa-youtube"></i></div>
        </div>
    </div>
  </div>
</div>

