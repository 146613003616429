import {Injectable, Injector} from '@angular/core';
import { DineGift } from './models/dine-gift.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';

@Injectable({
    providedIn: 'root'
})
export class GuruApiDineGiftService extends BaseApiService {
    options: object = {};
    optionList: DineGift[] = [];

    constructor(private injector: Injector) {
        super(injector, 'dinegift/');
    }
    loadOptions(): Observable<ApiListResponseModel<DineGift>> {
        return super.getList<DineGift>();
    }
    loadOption(id): Observable<DineGift> {
        const response = super.getDetail<DineGift>(id);
        response.subscribe(
            (data: DineGift) => {
                this.options[id] = data;
            }, error => {
                console.log('Error loading data: ', error);
            }
        );
        return response;
    }
    getOptions(): DineGift[] {
        return this.optionList;
    }

    setOptions(opts: DineGift[]): void {
        this.optionList = opts;
        this.optionList.forEach((ev: DineGift) => {
            this.options[ev.id] = ev
        })
    }

    getOption(optId): DineGift {
        return this.options[optId];
    }

    resetOptions(): void {
        this.optionList =[];
        this.options = {};
    }
    clearData(): void {
        this.resetOptions();
    }

}