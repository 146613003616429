<div class="about-container text-center">
  <!-- <app-logo></app-logo> -->
  <img [src]="appAbout.photo_1.full" class="about-img"/>
  <div class="col-12 info text-left">
    <h2>{{'about-us-page.about-us' | translate | titlecase}}</h2>
    <p>
      {{appAbout.cleaned_overview}}
    </p>
  </div>
  <div class="spacer" style="min-height: 20vh"></div>
</div>
