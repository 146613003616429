<!-- <p>event-carousel works!</p> -->
<!-- <div class="titling">
    <span class="activities-title">{{'events-list.activities' | translate | titlecase}}</span>
    <span class="see-more" (click)="goToEvents()">{{'events-list.seemore' | translate | uppercase}}</span>
</div>
<div class="container-fluid event-container">
    <div *ngIf="events?.length" class="row event-list-horizontal">
        <div *ngFor="let event of events">
            <div class="col" (click)="goToEvent(event.event.id)">
                <img [src]="event.event.default_image.original" class="event-image"/>
                <div class="event-info">
                    <div class="event-name">
                        <p>{{event.event.name}}</p>
                    </div>
                    <p style="font-size: 14px; color: grey">{{formatDate(event.start_time)}}</p>
                </div>
            </div>
        </div>
      </div>
</div> -->


<app-generic-carousel
    [rowTitle]="'events-list.activities'"
    [items]="events"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
    [seeMoreTitle]="'events-list.seemore'"
    [SeeMore]="goToEvents"
    [isLoading]="loading"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item >
    <div (click)="goToEvent(item.event.id, item.start_time)">
        <img [src]="item.event.default_image.thumb_manager" class="carousel-item-image-single"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.event.name}}</p>
            </div>
            <p style="font-size: 14px; color: grey">{{formatDate(item.start_time)}}</p>

        </div>
    </div>
</ng-template>