import { Component, OnInit } from '@angular/core';
import { GuruApiNewsService } from '../guru-api/guru-api-news.service';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewsItem } from '../guru-api/models/news.model';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss']
})
export class NewsCarouselComponent implements OnInit {
  news: NewsItem[] = [];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private guruNewsService: GuruApiNewsService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadNews();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadNews(): void {
    const news = this.guruNewsService.getNews();
    if (news) {
      this.news = news;
      return
    }
    this.guruNewsService.loadNews()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.news = data.objects;
      this.guruNewsService.setNews(this.news)
    })
  }
  goToNews(id: number) {
    this.router.navigate(["detail/news/" + id])
  }
  openTramSite():void {
    this.router.navigate(['detail/tramtour/0'])
    // Browser.open({url: 'https://spacecenter.org/exhibits-and-experiences/nasa-tram-tour/'})
    // Browser.addListener("browserFinished", () => {

    // })
  }
}
