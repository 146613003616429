<div class="display-box"  #outlet>
    <div class="back-bar" 
    *ngIf="(!isWeb && !isHome()) || debug"
    (click)="backClicked()">
        <i class="fas fa-chevron-left" ></i>
    </div>
    <div class="back-buffer"
    *ngIf="(isWeb || isHome()) || debug"
    >
        
    </div>
    <div class="router-container">
        <ng-content></ng-content>
    </div>
</div>
