import { HttpClient } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import { NavApiService } from './base/nav-api.service';
import { NavResponse, NavRequest, NavStep } from './models/nav.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NavigationService extends NavApiService {
 
    constructor(private injector: Injector, ) {
        super(injector, "path")
    }

    getRoute(req: NavRequest): Observable<NavResponse> {
        return super.post(req)
    }
}