<!-- <p>section-carousel works!</p> -->
<!-- <div class="titling"> -->
    <!-- <span class="activities-title">{{'site-section-list.activities' | translate | titlecase}}</span> -->
    <!-- <span class="see-more" >{{'dining-list.seemore' | translate | uppercase}}</span> -->
<!-- </div> -->
<!-- <div class="container-fluid section-container">
    <div *ngIf="sections?.length" class="row section-list-horizontal">
        <div *ngFor="let section of sections">
            <div class="col" (click)="goToSection(section.id)">
                <img [src]="section.list_page_image.original" class="section-image"/>
                <div class="section-info">
                    <div class="section-name">
                        <p>{{section.name}}</p>
                    </div>
                    <br/>
               </div>
            </div>
        </div>
      </div>
</div> -->

<app-generic-carousel
    [rowTitle]="'site-section-list.activities'"
    [items]="sections"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
</app-generic-carousel>
<ng-template #itemTemplate let-item >
    <div (click)="goToSection(item.id)">
        <img [src]="item.list_page_image.thumb_manager" class="carousel-item-image-single"/>
        <div class="item-info">
            <div class="item-name">
                <p>{{item.name}}</p>
            </div>

        </div>
    </div>
</ng-template>