import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SplashPageComponent} from './splash-page/splash-page.component';
import {LanguagePageComponent} from './language-page/language-page.component';
import {HomePageComponent} from './home-page/home-page.component';
import {AboutUsPageComponent} from './about-us-page/about-us-page.component';
import {SettingsPageComponent} from './settings-page/settings-page.component';
import {MoreMenuComponent} from './more-menu/more-menu.component';
import {FormsModule} from '@angular/forms';
import {SearchTourPageComponent} from './search-tour-page/search-tour-page.component';
import {TourIntroPageComponent} from './tour-intro-page/tour-intro-page.component';
import { TourStopPageComponent } from './tour-stop-page/tour-stop-page.component';
import {MapListComponent} from './map-list/map-list.component';
import {MapPageComponent} from './map-page/map-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { EventsPageComponent } from './events-page/events-page.component';
import { EventsItemPageComponent } from './events-item-page/events-item-page.component';
import { ArcmapComponent } from './arcmap/arcmap.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { MoreInfoPageComponent } from './more-info-page/more-info-page.component';
import { HoursPageComponent } from './hours-page/hours-page.component';
import { BuyTicketsComponent } from './buy-tickets/buy-tickets.component';
import { PermissionsScreenComponent } from './permissions-screen/permissions-screen.component';
import { NoDataPageComponent } from './no-data-page/no-data-page.component';
const routes: Routes = [
  {path: '', redirectTo: 'language', pathMatch: 'full'},
  {path: 'language', component: LanguagePageComponent},
  {path: 'home', component: HomePageComponent},
  {path: 'about-us', component: AboutUsPageComponent},
  {path: 'settings', component: SettingsPageComponent},
  {path: 'search', component: SearchTourPageComponent},
  {path: 'tour/:id', component: TourIntroPageComponent},
  {path: 'maps', component: MapListComponent},
  {path: 'maps/:mapId', component: MapPageComponent},
  {path: 'more', component: MoreMenuComponent},
  {path: 'more/about-us', component: AboutUsPageComponent},
  {path: 'more/settings', component: SettingsPageComponent},
  {path: 'tourStop/:tourId/:tourStopIndex', component: TourStopPageComponent},
  {path: 'tourStop/:tourId/:tourStopIndex/:itemId', component: TourStopPageComponent},
  {path: 'item/:itemId', component: ItemDetailsComponent},
  {path: 'category/:id', component: CategoryDetailsComponent},
  {path: 'events', component: EventsPageComponent},
  {path: 'events/:id', component: EventsItemPageComponent},
  {path: 'maptest', component: ArcmapComponent},
  {path: 'detail/:kind/:id', component: DetailPageComponent},
  {path: 'moreinfo', component: MoreInfoPageComponent},
  {path: 'moreinfo/hours', component: HoursPageComponent},
  {path: 'moreinfo/tickets', component: BuyTicketsComponent},
  {path: 'permissions', component: PermissionsScreenComponent},
  {path: 'nodata', component: NoDataPageComponent}
];

@NgModule({
  imports: [FormsModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
