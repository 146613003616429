import { registerPlugin } from "@capacitor/core";
import { PermissionStatus } from "src/definitions";
export interface PermissionsManager {
    checkPermissions(): Promise<PermissionStatus>;
    requestPermissions(): Promise<PermissionStatus>;
    requestPermission(options: { value: string }): Promise<any>;
    checkPermission(options: {value: string}): Promise<any>;
}

const PermissionsManager = registerPlugin<PermissionsManager>('PermissionsManager')
export default PermissionsManager