import { Component, Input, OnInit } from '@angular/core';
// import { DiningItemModel, DiningResponseModel } from '../guru-api/models/options.model';
import { Subject, Subscription } from 'rxjs';
// import { GuruApiDiningService } from '../guru-api/guru-api-options.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {Router} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { GuruApiDineGiftService } from '../guru-api/guru-api-dinegift.service';
import { DineGift } from '../guru-api/models/dine-gift.model';

@Component({
  selector: 'app-dining-carousel',
  templateUrl: './dining-carousel.component.html',
  styleUrls: ['./dining-carousel.component.scss']
})
export class DiningCarouselComponent implements OnInit {
  @Input() todayOnly?: boolean = false;
  options: DineGift[];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private guruApiDiningService: GuruApiDineGiftService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadDinings();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadDinings(): void {
    const options = this.guruApiDiningService.getOptions();
    if (options && options.length) {
      // this.filterTours(tours);
      // this.options = options.slice(0, 10);
      this.filterData(options);
      return;
    }

    this.guruApiDiningService.loadOptions()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        this.filterData(data.objects)
      }
    )
  }
  filterData(options: DineGift[]) {
    const diningList = options.filter(d => d.dine);
    this.options = diningList;
    this.guruApiDiningService.setOptions(options);
  }
  formatDate(toParse: number): string {
    var setDate = moment.unix(toParse);
    return setDate.calendar() + " " + setDate.format("LT");
  }
  goToDinings() {
    this.router.navigate(['options']);
  }
  goToDining(id: number) {
    this.router.navigate(["detail/dining/" + id])
  }
}
