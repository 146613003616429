<div class="container-fluid">
    <div class="detail-image-display">
        <img [src]="tramtour.images[0].image.original">
    </div>
    <div class="detail-title">
        <p>{{tramtour.name}}</p>
    </div>
    <div class="detail-description">
        <!-- <p class="subtitle">Description</p> -->
        <p [innerHTML]="tramtour.description"></p>
    </div>
    <ion-accordion-group>
        <ion-accordion *ngFor="let tourItem of tramtour.tour_items">
            <ion-item slot="header" color="dark">
                <ion-label>{{tourItem.item.name}}</ion-label>
            </ion-item>
            <div class="ion-padding accordian-content" slot="content" [innerHTML]="tourItem.item.description"></div>
        </ion-accordion>
    </ion-accordion-group>
</div>