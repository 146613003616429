<app-loading-page></app-loading-page>
<div class="perm-background">
    <div class="circle-layer" *ngIf="stepIndex == 0">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
    <div class="star-layer" *ngIf="stepIndex == 1">
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
    </div>
    <div class="container-fluid text-box">
        <div *ngFor="let step of steps();let i = index">
            <div *ngIf="stepIndex == i" class="step-box">
                <h3>{{step['header']}}</h3>
                <p>{{step['text']}}</p>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="dot-layer">
            <app-nav-dots [count]="steps().length" [selectedIndex]="stepIndex" activeColor="grey" inactiveColor="white"></app-nav-dots>
        </div>
        <div class="button-box">
            <ion-button shape="round" class="skip" (click)="skip()">Skip</ion-button>
            <ion-button shape="round" class="confirm" (click)="requestPermission()">Confirm</ion-button>
        </div>
    </div>

</div>