import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelServiceService {
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor() {
  }

  cancelPendingRequests(): void {
    this.unsubscribe$.next();
  }

  onCancelPendingRequests(): Observable<void>{
    return this.unsubscribe$.asObservable();
  }
}
