import { Component, Input, OnInit } from '@angular/core';
// import { SectionItemModel, SectionResponseModel } from '../guru-api/models/sections.model';
import { Subject, Subscription } from 'rxjs';
// import { GuruApiSectionService } from '../guru-api/guru-api-sections.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {Router} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { GuruApiMapService } from '../guru-api/guru-api-map.service';
import { SiteSectionModel } from '../guru-api/models/site-section.model';
@Component({
  selector: 'app-site-section-carousel',
  templateUrl: './site-section-carousel.component.html',
  styleUrls: ['./site-section-carousel.component.scss']
})
export class SiteSectionCarouselComponent implements OnInit {
  @Input() todayOnly?: boolean = false;
  sections: SiteSectionModel[];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private guruApiSectionService: GuruApiMapService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadSections();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadSections(): void {
    const sections = this.guruApiSectionService.getMapItems();
    if (sections && sections.length) {
      // this.filterTours(tours);
      // this.sections = sections.slice(0, 10);
      this.filterData(sections);
      return;
    }

    this.guruApiSectionService.loadMapItems()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        // this.fil
        this.filterData(data.objects)
      }
    )
  }
  filterData(sectionsData: SiteSectionModel[]) {
    let sectionList = sectionsData;
    // if (this.todayOnly) {
    //   sectionList = sectionsData.filter(e => {
    //     return moment.unix(e.start_time).date() == moment().date();
    //   })
    // } else {
    //   sectionList = sectionsData.filter(e => e.start_time >= new Date().getSeconds());
    // }
    // const sectionList = 
    // sectionList.sort((a, b)=> b.start_time - a.start_time)
    this.sections = sectionList;
    this.guruApiSectionService.setMapItems(sectionsData);
  }
  formatDate(toParse: number): string {
    var setDate = moment.unix(toParse);
    return setDate.calendar() + " " + setDate.format("LT");
  }
  goToSections() {
    this.router.navigate(['sections']);
  }
  goToSection(id: number) {
    this.router.navigate(["detail/section/" + id])
  }
}
