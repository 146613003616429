import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-generic-carousel',
  templateUrl: './generic-carousel.component.html',
  styleUrls: ['./generic-carousel.component.scss'],
})
export class GenericCarouselComponent implements OnInit {
  // @ContentChild(TemplateRef, {static: true}) 

  scrollIndex: number = 0;
  scrollPos: number = 0;
  scrollStart: number = 0;
  touchStart: number = 0;
  touchPointPadding = 0;
  @Input() items: object[] = null;
  @Input() ItemClickHandler: Function
  @Input() SeeMore: Function
  @Input() rowTitle: string
  @Input() seeMoreTitle: string
  @Input() itemTemplate: TemplateRef<any>;
  @Input() singleElement: boolean;
  @Input() headerClickHandler: Function
  @Input() isLoading: boolean;
  // @Input() ScrollHandler: Function
  constructor(private appDetails: GuruApiAppService, private router: Router) { }

  ngOnInit(): void {
  }
  isWeb(): boolean {
    // return false;
    return this.appDetails.deviceInfo == "web"
  }
  getItemList(): object[] {
    if (this.useSingleDisplay()) {
      return [this.items[this.scrollIndex]]
    } else {
      return this.items
    }
  }
  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
  }
  scrollMove($event) {
    // $event.preventDefault()
    // console.log("touch move")
    this.clickDistanceFromObject($event)

    if (!this.useSingleDisplay()) {
      return
    }
    // console.log($event)
    // this.scrollPos = $event.changedTouches[0].clientX - $event.changedTouches[0].target.clientWidth/2
    var rect = $event.target.getBoundingClientRect();
    // console.log($event.changedTouches[0].clientX , $event.changedTouches[0].target.clientWidth/2)
    this.scrollPos = $event.changedTouches[0].clientX - this.touchPointPadding;
    // console.log(this.scrollPos)
  }
  scrollCapture($event) {
    
    this.touchStart = $event.timeStamp;
    // console.log("touch start")
    // console.log($event)
    // $event.preventDefault()

    if (!this.useSingleDisplay()) {
      return
    }

    
    this.touchPointPadding = this.getTouchpointPadding($event);
    this.scrollPos = $event.changedTouches[0].clientX - this.touchPointPadding;
    this.scrollStart = $event.changedTouches[0].clientX;
    // if (this.singleElement) {
    //   let scroll = $event.touches[0].pageX;
    //   console.log(scroll)
    //   if (scroll > this.scrollPos) {
    //     // console.log("right")
    //     if (this.scrollIndex + 1 <= this.items.length) {
    //       this.scrollIndex++;
    //     }
        
    //   }  else {
    //     if (this.scrollIndex - 1 >= 0) {
    //       this.scrollIndex--;
    //     }
    //     // console.log("left")
    //   }
    //   this.scrollPos = scroll
    // }

  }
  scrollEnd($event) {
    // const touchEnd = new Date()
    const touchEnd = $event.timeStamp
    console.log("touch end: " + (touchEnd- this.touchStart))
    if ( touchEnd- this.touchStart < 60) {
      this.resetScrollNav()
      return
    }
    if (!this.useSingleDisplay()) {
      return
    }
      // console.log($event)
      let scroll = $event.changedTouches[0].clientX;
      $event.changedTouches[0].target.style.webkitTransition = 'all 0.5s ease-out';
      setTimeout(() => {
        $event.changedTouches[0].target.style.webkitTransition = '';
      }, 1000)
      console.log("scroll", scroll)
      console.log("scroll start", this.scrollStart)
      console.log("diff", Math.abs(scroll - this.scrollStart))
      const scrollDiff = Math.abs(scroll - this.scrollStart)
      if (scrollDiff > 60){
        if (scroll < this.scrollStart) {
        
          // console.log("right")
          if (this.scrollIndex + 1 < this.items.length) {
            this.scrollIndex++;
          }
          
        }  else {
          if (this.scrollIndex - 1 >= 0) {
            this.scrollIndex--;
          }
        }
      }
      this.resetScrollNav()
    }
  resetScrollNav() {
    this.scrollPos = 0;
    this.scrollStart = 0;
    this.touchPointPadding = 0;
    this.touchStart =0;
  }
  dotCount(): number[] {
    if (!this.items) {
      return []
    }
    return Array(this.items.length).fill(0).map((x, i) => i)
  }
  useSingleDisplay(): boolean {
    return this.singleElement 
    // && this.appDetails.deviceInfo != 'web'
  }
  clickDistanceFromObject($event): number {
    var rect = $event.target.getBoundingClientRect();
    var x = $event.targetTouches[0].clientX
    var itemLeftMaxDist = rect.right;
    var itemLeftMinDist = rect.left;
    // console.log("X: "+ x);
    // console.log("Left Min: "+ itemLeftMinDist  + " Left Max: " + itemLeftMaxDist);
    // console.log("Dist from Left: " + (itemLeftMinDist + x))
    var mousePos = (itemLeftMinDist + x);
    return itemLeftMaxDist - x;
  }
  getTouchpointPadding($event): number {
    var rect = $event.target.getBoundingClientRect();
    var x = $event.targetTouches[0].clientX
    var itemLeftMaxDist = rect.right;
    var itemLeftMinDist = rect.left;
    console.log("max: "+ itemLeftMaxDist + " min: " + itemLeftMinDist + " x: " + x)
    return x;
  }
  navClick(dir: string) {
    switch (dir) {
      case 'right':
          if (this.scrollIndex == this.items.length - 1) {
            return
          }
          this.scrollIndex++;
        break;
        case 'left':
          if (this.scrollIndex == 0) {
            return
          }
          this.scrollIndex--;
          break;
    }
  }
  headerClick() {
    if (this.headerClickHandler) {
      this.headerClickHandler()
    }
  }
}
