<div class="container-fluid">
  <div class="photo-container" *ngIf="photoObj && photoObj.length">
    <img [src]="url" class="img-fluid"
         [ngClass]="{'next-img': animNext, 'prev-img': animPrev}"
         (load)="onLoad()"
         (touchstart)="swipe($event, 'start')"
         (touchend)="swipe($event, 'end')"
    >

    <button class="nav-icon prev" *ngIf="index" (click)="prev()" [ngClass]="{'ui-hide': animNext || animPrev}"><i class="fas fa-chevron-left"></i></button>
    <button class="nav-icon next" *ngIf="index < length-1" (click)="next()" [ngClass]="{'ui-hide': animNext || animPrev}"><i class="fas fa-chevron-right"></i></button>

    <div class="loader" *ngIf="(animNext || animPrev) && !(animNext && animPrev)">
      <mat-progress-spinner mode="indeterminate">
      </mat-progress-spinner>
    </div>

    <div class="object-count" [ngClass]="{'ui-hide': animNext || animPrev}">
      <label>{{index+1}}/{{length}}</label>
    </div>

    <div class="footer" *ngIf="caption" [ngClass]="{'ui-hide': animNext || animPrev}">
      <label>{{caption}}</label>
    </div>

    <button class="nav-icon close-icon" (click)="close()" [ngClass]="{'ui-hide': animNext || animPrev}">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="video-container" *ngIf="videoObj && !photoObj">
    <iframe [src]="url | safe" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <button class="nav-icon close-icon" (click)="close()">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
