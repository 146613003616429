import {Injectable, Injector} from '@angular/core';
import { NewsItem } from './models/news.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';

@Injectable({
    providedIn: 'root'
})
export class GuruApiNewsService extends BaseApiService {
    newsItems: NewsItem[] = null;
    newsItemDict: object = {};
    constructor(private injector: Injector) {
        super(injector, 'news/');
    }
    loadNews(): Observable<ApiListResponseModel<NewsItem>> {
        return super.getList<NewsItem>();
    }
    getNews(): NewsItem[] {
        return this.newsItems;
    }
    setNews(items: NewsItem[]) {
        this.newsItems = items;
        items.forEach((nx) => {
            this.newsItemDict[nx.id] = nx;
        })
    }
    getNewsItem(id: number): NewsItem {
        return this.newsItemDict[id]
    }
    clearData(): void {
        this.newsItems = null;
        this.newsItemDict = null;
    }

}