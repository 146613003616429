import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-data-page',
  templateUrl: './no-data-page.component.html',
  styleUrls: ['./no-data-page.component.scss']
})
export class NoDataPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log("no data loaded")
  }
  retry() {
    location.reload()
  }
}
