import {Component, OnInit, OnDestroy} from '@angular/core';
import {GuruApiToursService} from '../guru-api/guru-api-tours.service';
import { of, Subject } from 'rxjs';
import {TourModel} from '../guru-api/models/tour.model';
import {Router, ActivatedRoute} from '@angular/router';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-tour-intro-page',
  templateUrl: './tour-intro-page.component.html',
  styleUrls: ['./tour-intro-page.component.scss']
})
export class TourIntroPageComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  tourId: number;
  tour: TourModel;
  traceTimeSpent: any = null;


  constructor(private guruApiToursService: GuruApiToursService,
              private route: ActivatedRoute,
              private router: Router,
              private guruFirebaseService: GuruFirebaseService,
              private loadingService: LoadingService
              ) {


  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(mergeMap(paramMap => {
        this.tourId = Number(paramMap.get('id'));
        const _tour = this.guruApiToursService.getTour(this.tourId);
        if(_tour){
          this.tour = _tour;
          return of(<any>{show: false});
        }
        return this.loadingService.loaderState
      }))
      .subscribe( state => {
        if(!state.show){
          this.traceTimeSpent = this.guruFirebaseService.performance.trace('Tour Intro Time Spent')
          this.traceTimeSpent.start();
          if (!this.tour) {
            this.loadTour(this.tourId);
          }
        }
      })
  }

  loadTour(tourId: number): void {
    if(!this.tour){
      const _tour = this.guruApiToursService.getTour(this.tourId);

      if(_tour){
        this.tour = _tour;
        return;
      }
    }

    const traceTourLoad = this.guruFirebaseService.performance.trace('Loading Tour')
    traceTourLoad.start();
    this.guruApiToursService.loadTour(tourId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      (data: TourModel) => {
        this.tour = data;
        this.guruFirebaseService.logVisitTourPage(this.tour);
        traceTourLoad.putAttribute('Name', this.tour.name);
        this.traceTimeSpent.putAttribute('Name', this.tour.name);
        traceTourLoad.stop();
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
          traceTourLoad.stop();
        });
  }

  ngOnDestroy(): void {
    this.traceTimeSpent.stop();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  startTour(): void{
    this.router.navigate(['tourStop/' + this.tourId + '/0']);
  }
}
