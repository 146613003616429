<div class="container-fluid" *ngIf="dining">
    <div class="detail-image-display">
        <img [src]="dining.main_photo.original">
    </div>
    <div class="detail-title">
        <p>{{dining.info_name}}</p>
    </div>
    <div class="detail-description">
        <!-- <p class="subtitle">Description</p> -->
        <p id="innerdesc" [innerHTML]="dining.description"></p>
    </div>
</div>