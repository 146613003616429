import {Component, OnInit, Input} from '@angular/core';
import {MoreMenuStateService} from '../services/more-menu-state.service';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
  // active = 'home';
  @Input() active = 'home'
  onSelect(button: string): void {
    this.active = button;
    if (this.active !== 'more') {
      this.moreMenuState.setState(false);
      return;
    }
    this.moreMenuState.toggleState();
  }

  constructor(private moreMenuState: MoreMenuStateService) {

  }

  ngOnInit(): void {
  }

}
