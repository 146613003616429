<!-- <p>event-carousel works!</p> -->
<div class="titling">
    <span class="row-title" (click)="headerClick()">{{rowTitle | translate | titlecase}}</span>
    <span class="see-more" (click)="SeeMore()" *ngIf="seeMoreTitle">{{seeMoreTitle | translate | uppercase}}</span>
</div>
<div class="display-box" style="display: block;" *ngIf="isLoading">
    <div style="width: 100%; display: block">
        <ion-skeleton-text [animated]="true" style="width: 90%;"></ion-skeleton-text>
    </div>
    <div  style="width: 100%; display: block">
        <ion-skeleton-text [animated]="true" style="width: 90%;"></ion-skeleton-text>
    </div>
    <div   style="width: 100%; display: block">
        <ion-skeleton-text [animated]="true" style="width: 90%;"></ion-skeleton-text>
    </div>
</div>
<div class="display-box" *ngIf="!isLoading">
    <div class="nav-button" *ngIf="isWeb() && items?.length > 1">
        <div class="left" *ngIf="isWeb() && items?.length" (click)="navClick('left')">
            <ion-button fill="clear"  >
                <i class="fas fa-chevron-left"></i>
            </ion-button>
        </div>

    </div>
    <div class="container-fluid carousel-container">
        <div *ngIf="items?.length" class="row carousel-list-horizontal" [ngStyle]="useSingleDisplay() && {'justify-content': 'center'}" >
            <div *ngFor="let item of getItemList(); let i = index" class="item-box" [style.left.px]="scrollPos" [ngClass]="{'multi-item': !useSingleDisplay(), 'single-item': useSingleDisplay()}"  (touchmove)="scrollMove($event)" (touchstart)="scrollCapture($event)" (touchend)="scrollEnd($event)">
                <!-- <div class="col"> -->
                    <ng-container class="item-insert" *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index:i}"></ng-container>
                    <!-- <ng-content [item]="item"></ng-content> -->
                    <!-- <img [src]="getImageForItem(i))" class="carousel-item-image"/>
                    <div class="item-info">
                        <div class="item-name">
                            <p [innerHTML]="get"></p>
                        </div>
                        <br/>
                        <p>{{formatDate(event.start_time)}}</p>
                    </div> -->
                <!-- </div> -->
            </div>
            
          </div>
          <div *ngIf="useSingleDisplay()" class="indicator-nav">
            <app-nav-dots [count]="dotCount().length" [selectedIndex]="scrollIndex"></app-nav-dots>
            <!-- <span *ngFor="let i of dotCount()" class="indicator-dot" [ngClass]="{active: scrollIndex == i}">
                <i class="fas fa-circle" ></i>
            </span> -->
        </div>
          <div *ngIf="!items">
            <!-- <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text> -->
          </div>
    </div>
    <div class="nav-button" *ngIf="isWeb() && items?.length > 1">

        <div class="right"  *ngIf="isWeb() && items?.length" (click)="navClick('right')">
            <ion-button fill="clear" >
                <i class="fas fa-chevron-right"></i>
            </ion-button>
        </div>
    </div>
</div>

