import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuruApiToursService } from '../guru-api/guru-api-tours.service';
import { Subscription } from 'rxjs';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { GuruApiTourItemsService } from '../guru-api/guru-api-item.service';
import { TourModel } from '../guru-api/models/tour.model';
import { TourStopModel } from '../guru-api/models/tour-stop.model';
import { AudioService } from '../services/audio/audio.service';
import { StreamState } from '../services/audio/stream-state';
import { Location } from '@angular/common';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-tour-stop-page',
  templateUrl: './tour-stop-page.component.html',
  styleUrls: ['./tour-stop-page.component.scss']
})
export class TourStopPageComponent implements OnInit, OnDestroy {
  tourId: number;
  tourStopIndex: number;
  tourObj: TourModel;
  tourStop: TourStopModel;
  tourSubscription$: Subscription;

  traceTimeSpent: any;

  set tour(tModel: TourModel) {
    this.tourObj = tModel;
    this.tourStop = tModel.tour_items[this.tourStopIndex];
  }

  get tour(): TourModel {
    return this.tourObj;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private guruApiToursService: GuruApiToursService,
              private guruFirebaseService: GuruFirebaseService,
              private loadingService: LoadingService
  ) {

  }

  ngOnInit(): void {
    this.loadingService.appState.subscribe(state => {
      if (state) {
        this.traceTimeSpent = this.guruFirebaseService.performance.trace('Time Spent On Tour Stop Page');
        this.traceTimeSpent.start();
        this.route.paramMap.subscribe(params => {
          this.tourId = Number(params.get('tourId'));
          this.tourStopIndex = Number(params.get('tourStopIndex'));
          this.loadData();
        });
      }
    })
  }

  ngOnDestroy(): void {
    if (this.tourSubscription$) {
      this.tourSubscription$.unsubscribe();
    }
  }

  loadData(): void {
    const traceTourStopLoad = this.guruFirebaseService.performance.trace('Tour Stop Load');
    traceTourStopLoad.start();
    const tour = this.guruApiToursService.getTour(this.tourId);
    if (tour) {
      this.tour = tour;
      if (this.getItem()) {
        this.guruFirebaseService.logVisitTourStopPage(this.getItem());
      }
      this.traceTimeSpent.putAttribute('Name', this.tour.name);
      traceTourStopLoad.putAttribute('Name', this.tour.name);
      traceTourStopLoad.stop();
      return;
    }

    this.tourSubscription$ = this.guruApiToursService.loadTour(this.tourId).subscribe(
      (data: TourModel) => {
        this.tour = data;
        if (this.getItem()) {
          this.guruFirebaseService.logVisitTourStopPage(this.getItem());
        }
        traceTourStopLoad.putAttribute('Name', this.tour.name);
        traceTourStopLoad.stop();
      }, error => {
        console.log('error', error);
        traceTourStopLoad.stop();
      });
  }

  prevStop(): void {
    this.router.navigate(['tour/' + this.tour.id + '/']);
  }

  nextStop(): void {
    this.router.navigate(['tourStop/' + this.tour.id + '/' + (this.tourStopIndex + 1)]);
  }


  isFirstStop(): boolean {
    return this.tourStopIndex === 0;
  }

  getItem(): TourItemModel {
    return this.tourObj.tour_items[this.tourStopIndex].item;
  }

  isLastStop(): boolean {
    return this.tourStopIndex + 1 === this.tour.tour_items.length;
  }

}
