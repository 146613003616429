import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DineGift } from 'src/app/guru-api/models/dine-gift.model';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-dining-detail-display',
  templateUrl: './dining-detail-display.component.html',
  styleUrls: ['./dining-detail-display.component.scss']
})
export class DiningDetailDisplayComponent implements OnInit {
  @Input() dining: DineGift;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.dining)
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dining) {
      setTimeout(() => {
        const innerDesc =document.getElementById("innerdesc");
        if (innerDesc) {
          const aVals = innerDesc.getElementsByTagName("a")
          if (aVals.length == 1) {
            // console.log(aVals[0])
            const hrefVal = aVals[0].href;
            console.log(hrefVal)
            Browser.open({
              url: hrefVal
            })
            console.log("open", hrefVal)
          }
        }
        
      }, 100)
    }
  }
}
