import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NavService {
  private pageStates = {}
  private pageBackIntercepts: Subject<Object>;
  backIntercepts: Observable<Object>
  constructor() {
    this.pageBackIntercepts = new Subject<Object>();
    this.backIntercepts = this.pageBackIntercepts.asObservable();
   }

  setPageData(pageURL: string, state: NavState): void {
    console.log("new nav state", state)
    this.pageStates[pageURL] = state;
  }
  getPageData(pageURL: string): NavState { // Clears the state on retrieval 
    const currentState = this.pageStates[pageURL]
    if (!currentState) {
      return null
    }
    this.setPageData(pageURL, null)
    return currentState
  }
  incPageIntercept(pageURL: string) {
    if (this.pageBackIntercepts[pageURL]) {
      this.pageBackIntercepts[pageURL]++;
    } else {
      this.pageBackIntercepts[pageURL] = 1;
    }
    this.pageBackIntercepts.next(this.pageBackIntercepts)
  }
  decPageIntercept(pageURL: string) {
    if (!this.pageBackIntercepts[pageURL]) {
      return
    }
    if (this.pageBackIntercepts[pageURL] == 0) {
      return
    }
    this.pageBackIntercepts[pageURL]--;
    this.pageBackIntercepts.next(this.pageBackIntercepts)

  }
  hasPageIntercepts(pageURL: string) {
    if (this.pageBackIntercepts[pageURL]) {
      return this.pageBackIntercepts[pageURL] > 0
    }
    return false
  }
  // clearPageData(): void
}

export class NavState {
  returnIndex: number;
}