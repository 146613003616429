<div class="container-fluid search-container">
  <div class="input-wrapper">
    <img src="assets/img/keypad-icon.svg" class="keypad-search-icon"
         [class.toggle-icon]="typing"
    >
    <input [type]="searchType" class="col-12 search-tour" s
           [attr.placeholder]="translationKey | translate"
           #searchTerm
           (input)="searchTours(searchTerm.value)">
  </div>
  <br />
  <div *ngIf="!query">
    <!-- <app-dining-carousel></app-dining-carousel> -->
    <app-info-carousel></app-info-carousel>
    <app-video-carousel></app-video-carousel>
  </div>
  <div *ngIf="query">
    <h1>{{'search-tour-page.search-results' | translate | titlecase}}</h1>
    <div *ngIf="searchResults">
      <div *ngFor="let sx of searchResults" class="search-result" (click)="handleResultClick(sx)">
        <div class="img-box col">
          <img class="img-fluid" [src]="sx.display_image"/>
        </div>
        <div class="col">
          <span class="result-title">{{sx.title}}</span>
        </div>
      </div>
    </div>
    <!-- <app-item-list
      [text]="translationKey | translate"
      [query]="query"
      [search_fields]="appDetails?.web_app_item_search_fields">

    </app-item-list> -->
  </div>
</div>
