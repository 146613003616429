<div class="container-fluid" *ngIf="section">
    <div class="detail-image-display">
        <img [src]="section.list_page_image.original">
    </div>
    <div class="detail-title">
        <p>{{section.name}}</p>
    </div>
    <div class="detail-description" >
        <!-- <p class="subtitle">Description</p> -->
        <div class="description-box" [ngClass]="{'collapsed': !showMore}">
            <p  [innerHTML]="descriptionText" ></p>
            
        </div>
        <div class="gradient-box"></div>
        <div (click)="toggleReadMore()" class="showMore">
            <a class="showMoreButton">{{readText}}</a>
        </div>
        <!-- <p [innerHTML]="section.description"></p> -->
    </div>
    <div>
        <!-- <h3>Items</h3> -->
        <div>
            <div *ngFor="let mx of section.items" class="item-listing" (click)="goToItem(mx.id)">
                <div class="item-image col">
                    <img [src]="mx.image.original" />
                </div>
                <div class="col">
                    <span>{{mx.name}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
