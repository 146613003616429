import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel, LocationDisplay} from '../guru-api/models/site-section.model';
import {Router} from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { TourModel } from '../guru-api/models/tour.model';
import { NavigationService } from '../guru-api/guru-api-navigation.service';
import { CoordinateSet, NavRequest, NavStep } from '../guru-api/models/nav.model';
import { Coordinate } from 'geojson-parser-js/models/geojson';
import { Geolocation, Position, WatchPositionCallback, ClearWatchOptions, CallbackID } from '@capacitor/geolocation';
import { AppConfigService } from '../services/app-config/app-config.service';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.scss']
})
export class MapListComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  map_items?: SiteSectionModel[];
  sitePoints?: LocationDisplay[] = [];
  navPoints?: NavStep[]
  inRange?: boolean;
  isolatedListing?: number;
  selectedPoint: LocationDisplay;
  map_display_items?: SiteSectionModel[];
  lastBackInterceptCount: number = 0;
  navRefreshInterval: any = null;
  constructor(private guruApiMapService: GuruApiMapService,
              private router: Router,
              private loadingService: LoadingService,
              private navService: NavigationService,
              private appConfig: GuruApiAppService,
              private pageNav: NavService) {
                this.isolatedListing = -1;
  }

  ngOnInit(): void {
    this.loadingService.appState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( state => {
        if (state){
          this.loadData();
        }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapListings(): SiteSectionModel[] {
    if (this.isolatedListing > -1) {
      return this.map_items.filter((vx) => {
        return vx.order == this.isolatedListing;
      })
    }
    if (!this.map_items) {
      return []
    }
    return this.map_items.filter((val) => !Number.isNaN(val?.locationDisplay?.latitude));
  }
  getMapItems() {
    return this.guruApiMapService.getMapItems()
  }
  loadData(): void {
    const data = this.guruApiMapService.getMapItems();
    // if (data){
    //   if (data[0].latitude) {
    //     this.map_items = data;

    //     if (this.map_items.length === 1){
    //       this.goToMap(0, '-s');
    //     }
    //     return;
    //   }
      
    // }
    this.guruApiMapService.loadMapItems()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      (d) => {
        this.map_items = d.objects;
        this.guruApiMapService.setMapItems(this.map_items);
        if (this.map_items.length === 1){
          this.goToMap(0, '-s');
        }
        this.sitePoints = new Array<LocationDisplay>();
        this.map_items.forEach((mx) => {
          this.guruApiMapService.loadMapItemDetail(mx.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (d) => {
              // console.log(d)
              // if (!d.latitude || !d.longitude) {
              //   console.log(d)
              //   return
              // }
              d.locationDisplay = {
                latitude: parseFloat(d.latitude),
                longitude: parseFloat(d.longitude),
                title: d.name,
                id: d.order,
              }
              this.guruApiMapService.setMapItem(d)
              this.map_items = this.guruApiMapService.getMapItems().sort((a: SiteSectionModel, b: SiteSectionModel) => {
                return a.order - b.order
              })
              this.sitePoints = (this.guruApiMapService.getMapItems().map((sim) => {
                return sim.locationDisplay
              }).filter((ld) => {
                return ld !== undefined
              }) as LocationDisplay[])
            }
          )
          
        })
        
      } , error => {
        console.log('error', error);
      });
  }

  getMapUrl(id: number): string{
    if (this.map_items) {
      return this.map_items[id].map_image.big_retina;
    }
    return '';
  }

  goToMap(id: number, flag = ''): void{
    console.log(this.map_items[id])
    this.router.navigate(['maps/' + id + flag]);
  }
  async goToSection(id: number) {
    this.navPoints = [];
    console.log(id)
    var sectionTarget = this.getMapById(id);
    // console.log(sectionTarget)
    this.isolatedListing = sectionTarget.order
    this.loadNavRoute(sectionTarget)
    clearInterval(this.navRefreshInterval)
    setTimeout(() => {
      this.navRefreshInterval = setInterval(() => {
        this.loadNavRoute(sectionTarget)
      }, 5000)
    }, 2000)
    // this.router.navigate(["detail/section/" + id])
  }
  async loadNavRoute(sectionTarget: SiteSectionModel) {
    let sourceCoords = {
      Lat: 29.551947,
      Long: -95.098779
    } as CoordinateSet
    if (this.appConfig.deviceInfo != 'web') {
      const newPost = await Geolocation.getCurrentPosition();
      sourceCoords.Lat = newPost.coords.latitude
      sourceCoords.Long = newPost.coords.longitude
    }

    let req = {
      Source: sourceCoords,
      Target: {
        Lat: parseFloat(sectionTarget.latitude),
        Long: parseFloat(sectionTarget.longitude)
      } as CoordinateSet
    } as NavRequest
    this.navService.getRoute(req)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((d) => {

        this.navPoints = d.steps;
        this.inRange = d.in_range;
  
      // console.log(d)
      
    })
  }
  getMapById(id: number) {
    return this.map_items.filter((mi) => {
      return mi.id == id
    })[0]
  }
  listingClick(id) {
    if (this.selectedPoint) {
      this.selectedPoint = null
      this.isolatedListing = -1;
      return
    }
    var sectionTarget = this.getMapById(id);
    this.isolatedListing = sectionTarget.order - 1
    this.selectedPoint = sectionTarget.locationDisplay
  }
  pointClick(px) {
    console.log(px)
    if (px.ld == null) {
      if (this.isolatedListing > -1) {
        this.isolatedListing = -1
        this.selectedPoint = null;
        return
      }
      return
    }
    // if (px.ld.id == this.isolatedListing) {
    //   this.isolatedListing = -1;
      
    // //   this.pageNav.decPageIntercept(this.router.url)
    //   return
    // }
    // this.pageNav.incPageIntercept(this.router.url)
    if (px.isFullscreen) {
      const mapItem = this.map_items.find((vx) => {
        return vx.order == px.ld.id
      })
      this.goToSection(mapItem.id)
    }
      this.isolatedListing = px.ld.id
      // console.log(px)
      // const targetEl = document.getElementById(px.ld.id)
      // console.log(targetEl.getBoundingClientRect())
      // targetEl.scrollIntoView({behavior: "smooth", block: "center"})
      // targetEl.classList.add("clickedItem")
      // setTimeout(() => {
      //   targetEl.classList.remove("clickedItem")
      // }, 2000)
    
    
    // this.viewportScroller.scrollToAnchor(px.id)
  }
  isolatedGoHere() {
    const mapItem = this.map_items.find((vx) => {
      return vx.order == this.isolatedListing
    })
    this.goToSection(mapItem.id)
  }
  isolatedSeeMore() {
    const mapItem = this.map_items.find((vx) => {
      return vx.order == this.isolatedListing
    })
    this.router.navigate(["detail/section/" + mapItem.id])
  }
  clearNavEmit() {
    clearInterval(this.navRefreshInterval)
  }
}
